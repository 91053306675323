export const LABELS = {
  CHECK_MAIL: "Check your Mail",
  PASSWORD_RECOVER_INSTRUCTION:
    "We have sent password recover instructions to your email",
  TERMS_CONDITIONS:
    "I have read and agree to the terms & conditions and privacy policy",
  PASSWORD_UPDATED: "Your password has been updated",
  LOGIN_AGAIN: "Please login again with new password",
  LOGIN_WARNING:
    "Use of this website is restricted to Dealerline,LLC and its authorised users. Unauthorized use of Dealerline's websites and systems including but not limited to authorized entry into Dealer Line's system, misuse of passwords, or misuse of any information within Dealer Line's websites, is strictly prohibited. All information and data acquired from Dealer Line's website and systems shall be confidential and proprietary to Dealer Line and its authorised users. Such information and data shall be used only as authorized by Dealer Line and shall not be disclosed or caused to be disclosed to any third party without written authorisation from Dealer Line.",
  LOGIN_DISCLOSE:
    "By supplying my user identification and password above, I agree to read the Dealer Line Use policy before accessing information on this website. I understand my responsibility under the Dealer Line Use Policy to protect the information and data on this website.",
};
