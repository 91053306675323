import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import PContainedButton from "../PContainedButton";
import "./InfoDialog.styles.css";

const InfoDialog = (props) => {
  const history = useHistory();
  const okayHandler = () => history.push("/");
  const { onClose, open, title, body } = props;
  const Icon = props.icon;
  return (
    <div>
      <Dialog onClose={onClose} open={open}>
        <DialogContent className="DialogContent">
          <Icon color="primary" gutterBottom className="Icon" />
          <Typography variant="h4" gutterBottom align="center">
            {title}
          </Typography>
          <Typography gutterBottom align="center">{body}</Typography>

          <PContainedButton
            className="Button"
            onClick={() => {
              onClose();
              okayHandler();
            }}
          >
            Okay
          </PContainedButton>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InfoDialog;
