import { gql } from "@apollo/client";

export const LOGIN = gql`

  mutation Login($loginInput: LoginInput!) {
    login(loginInput : $loginInput) {
      token
      agentUserId
      dealerUserId
      dealership
      email
      image
      name
      subscriptions {
        feature_id
        is_enable
      }
      errors {
        code
        message
        type
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      errors {
        type
        message
        code
      }
      email
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($resetPassword: ResetPasswordInput!) {
    resetPassword(resetPassword: $resetPassword) {
      errors {
        code
        message
        type
      }
      userId
    }
  }
`;

export const TRIGGER_SERVICELINE_KPI_STATS = gql`
  mutation getDealershipProductsWithSoldCount($userId: String) {
    getDealershipProductsWithSoldCount(userId: $userId) {
      carsSold
      details {
        gross
        count
        product_title
      }
    }
  }
`;

export const TRIGGER_SALESLINE_KPI_STATS = gql`
  mutation getDealershipExternalIntegrationCallCounts($userId: String) {
    getDealershipExternalIntegrationCallCounts(userId: $userId) {
      carsSold
      preQuals
      preQualsClosing
      vehVals
      vehValsClosing
      vinClosing
      vins
      sumFBGross
      avgFBGross
    }
  }
`;

export const TRIGGER_SALESLINE_MAIN_GRID = gql`
  query getDealerCustomer($dealerCustomerInput: DealerCustomerInput!) {
    getDealerCustomer(dealerCustomerInput: $dealerCustomerInput) {
      totalRecordsCount
      details {
        fico
        afterMarketGross
        compliance
        customer {
          apt_number
          city
          email
          mobile
          first_name
          middle_name
          last_name
          state
          street
          user_id
          work
          zipcode
          stock_number
        }
        date
        equity
        offerAfter
        payoff
        serviceAgentName
        vehicleValue
        soldAfter
        dealStatus
        vehicleUniqueId
        paymentId
        fbGross
        fbGross_vehicle
        prequalExpiryFlag
        requiredFlags
        completedFlags
        transactionId
        vehicle {
          make
          make_year
          miles
          model
          trim
          vehicle_id
          vin
        }
      }
    }
  }
`;

export const GET_VEHICLE_IDS = gql`
  mutation GetVehicleId($tradePendingInput: TradePendingInput!) {
    getVehicleId(tradePendingInput: $tradePendingInput) {
      vehicleIds {
        id
        distinct_key
        year
        make
        model
        trim
        drivetrain
        engine
        fuel_type
        body
        doors
      }
    }
  }
`;

export const GENERATE_TRADEPENDING_REPORT = gql`
  mutation GenerateTradePendingReport($tradePendingInput: TradePendingInput!) {
    generateTradePendingReport(tradePendingInput: $tradePendingInput) {
      report
    }
  }
`;

export const GET_DEALER_CUSTOMER = gql`
  mutation GetDealerCustomer($userId: String!) {
    getDealerCustomer(userId: $userId) {
      totalRecordsCount
      details {
        customer {
          user_id
          name
          email
          mobile
          work
          street
          city
          state
          zipcode
          customer_id
        }
        vehicle {
          vehicle_id
          vin
          make_year
          make
          model
          miles
          trim
          vv
        }
        date
        serviceAgent
        equity
        owned
        FICO
        compliance
        offerAfter
        soldAfter
        afterMarketGross
        vehicleSold
      }
    }
  }
`;

export const RECALL_PREQUAL = gql`
  mutation RecallPrequal($recallPrequalInput: RecallPrequalInput!) {
    recallPrequal(recallPrequalInput: $recallPrequalInput) {
      htmlResponse
      errors {
        code
        message
        type
      }
    }
  }
`;

export const UPDATE_VEHICLE_SOLD = gql`
  mutation UpdateVehicleSold($vehicleSoldInput: VehicleSoldInput!) {
    updateVehicleSold(vehicleSoldInput: $vehicleSoldInput) {
      customerId
      vehicleSoldStatus
    }
  }
`;

export const UPDATE_FBGROSS = gql`
  mutation UpdateFBGross($fbGrossInput: FBGrossInput!) {
    updateFBGross(fbGrossInput: $fbGrossInput) {
      customerUserId
      paymentId
      fbGrossValue
    }
  }
`;

export const CALL_PREQUAL = gql`
  mutation PreQual($preQualInput: PreQualInput!) {
    preQual(preQualInput: $preQualInput) {
      ficoScore
      errors {
        code
        message
        type
      }
    }
  }
`;

export const GENERATE_COMPLIANCE_REPORT = gql`
  mutation GenerateComplianceReport(
    $complianceReportInput: ComplianceReportInput!
  ) {
    generateComplianceReport(complianceReportInput: $complianceReportInput) {
      complianceReport
      errors {
        code
        message
        type
      }
    }
  }
`;
