import React, { useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import { useReactToPrint } from "react-to-print";
import { Card, DialogTitle, Button } from "@material-ui/core";

import PContainedButton from "../../../utils/UI_Elements/PContainedButton";
import TDangerButton from "../../../utils/UI_Elements/TDangerButton";
import { COLORS } from "../../../utils/colors";
import "./YMMDialog.styles.css";

const YMMDialog = (props) => {
  const { onClose, open, ymmm } = props;

  const printRef = useRef();

  const YMMHandlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <div>
      {ymmm === null ? null : (
        <Dialog
          onClose={onClose}
          open={open}
          fullWidth
          maxWidth={"sm"}
          scroll={"paper"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            style={{
              // background: `linear-gradient( to right,#212a38, #283b54, #2c4d71, #2d5f90, #2973b0 )`,
              background: "#283b54",
              color: COLORS.white,
            }}
          >
            YMMM Details
          </DialogTitle>
          <DialogContent
            className="hideScrollBar"
            ref={printRef}
            // style={{ backgroundColor: "#f9f9f9" }}
          >
            <div
              style={{
                padding: 20,
                backgroundColor: "#fafafa",
                borderRadius: 10,
                marginTop: 16,
                marginBottom: 16,
                boxShadow:
                  "0 7px 30px -10px rgba(150,170,180,0.8),0 15px 40px rgba(166,173,201,0.2)",
              }}
            >
              <div
                style={{
                  height: "160px",
                  width: "100%",
                  flexDirection: "column",
                  textAlign: "center",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <div className="YMMRow">
                  <Typography className="YMMObject">VIN</Typography>
                  <Typography className="YMMValue">
                    {ymmm.vin !== "" ? ymmm.vin.toUpperCase() : "N/A"}
                  </Typography>
                </div>
                <div className="YMMRow">
                  <Typography className="YMMObject">Year</Typography>
                  <Typography className="YMMValue">
                    {ymmm.make_year !== "" ? ymmm.make_year : "N/A"}
                  </Typography>
                </div>
                <div className="YMMRow">
                  <Typography className="YMMObject">Make </Typography>
                  <Typography className="YMMValue">
                    {ymmm.make !== "" ? ymmm.make : "N/A"}
                  </Typography>
                </div>
                <div className="YMMRow">
                  <Typography className="YMMObject">Model </Typography>
                  <Typography className="YMMValue">
                    {ymmm.model !== "" ? ymmm.model : "N/A"}
                  </Typography>
                </div>
                <div className="YMMRow">
                  <Typography className="YMMObject">Trim</Typography>
                  <Typography className="YMMValue">
                    {ymmm.trim !== "" ? ymmm.trim : "N/A"}
                  </Typography>
                </div>
                <div className="YMMRow">
                  <Typography className="YMMObject">Miles </Typography>
                  <Typography className="YMMValue">
                    {ymmm.miles !== "" ? ymmm.miles : "N/A"}
                  </Typography>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#f2f2f2" }}>
            <div className="YMMHBottomButton">
              <Button
                className="YMMHDialogButton"
                style={{ color: COLORS.primary, marginRight: 4 }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                className="YMMHDialogButton"
                style={{ backgroundColor: COLORS.primary, color: COLORS.white }}
                onClick={YMMHandlePrint}
              >
                Print
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default YMMDialog;
