import React from "react";
import Grid from "@material-ui/core/Grid";
import { useLocation } from "react-router-dom";

import Header from "../../../components/Home/Header/Header.component";
import SalesLine from "../../../components/Home/SalesLine/SalesLine.component";
import ServiceLine from "../../../components/Home/ServiceLine/ServiceLine.component";
import SLGrid from "../../../components/SalesLineGrid/SLGrid.component";
import MainGrid from "../../../components/SalesLineGrid/MainGrid/MainGrid.component";

import { HomeStyles } from "./Home.styles";

const Home = (props) => {
  const location = useLocation();

  if (location.state) {
    if (location.state.userId) {
      sessionStorage.setItem("user_id", location.state.userId);
    }

    if (location.state.dealershipName) {
      sessionStorage.setItem("dealership_name", location.state.dealershipName);
    }

    if (location.state.prequalSubscription) {
      sessionStorage.setItem(
        "prequal_subscription",
        location.state.prequalSubscription
      );
    }
    
    if (location.state.jwtToken) {
      sessionStorage.setItem("jwt_token", location.state.jwtToken);
    }
  }

  return (
    <div style={{ background: "#fafafa" }}>
      <Header handleLogout={props.handleLogout} />

      <Grid container>
        <Grid item xs="12" sm="6" style={HomeStyles.SalesGridMini}>
          <SalesLine />
        </Grid>
        <Grid item xs="12" sm="6" style={HomeStyles.SalesGridMini}>
          <ServiceLine />
        </Grid>
      </Grid>
      <MainGrid />
      {/* <SLGrid /> */}
    </div>
  );
};

export default Home;
