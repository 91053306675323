import { COLORS } from "../../../utils/colors";

export const RPStyles = {
  resetBox: {
    height: "100vh",
  },
  resetButton: {
    marginTop: 10,
    marginBottom: 10,
  },
  goBack: {
    alignSelf: "flex-end",
    color: COLORS.error,
    cursor: "pointer",
    marginTop:10
  },
};
