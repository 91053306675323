import React, { Fragment } from "react";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import { COLORS } from "../utils/colors";

const Theme = (props) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: COLORS.primary,
      },
      secondary: {
        main: COLORS.secondary,
      },
      success: {
        main: COLORS.success,
      },
      error: {
        main: COLORS.error,
      },
    },
    overrides: {
      MuiTable: {
        root: { border: "1px solid black" },
      },
      MuiTableRow: {
        root: {
          "&:nth-child(even)": {
            background: COLORS.oddTableBackground,
          },
          "&:nth-child(odd)": {
            background: "white",
          },
        },
        head: {},
      },
      MuiTableCell: {
        root: {
          padding: "4px",
          borderBottom: "none",
        },
        head: {
          fontWeight: "bold",
          textAlign: "center",
          color: "white",
        },
        body: {
          textAlign: "center",
        },
      },
    },
  });

  return (
    <Fragment>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </Fragment>
  );
};
export default Theme;
