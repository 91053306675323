import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { COLORS } from "../colors";

const styles = {
  root: {
    // background:
    //   "linear-gradient(to right, #2c4d71, #2d5680, #2d6090, #2c69a0, #2973b0)",
    background: COLORS.secondary,
    borderRadius: 25,
    border: 0,
    color: "white",
    height: 40,
    "&:hover": {
      backgroundColor: COLORS.secondary,
      color: "white",
      // boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, .35)",
    },
  },
};

const PConstainedButton = (props) => {
  const { classes, children, className, ...other } = props;

  return (
    <Button className={clsx(classes.root, className)} {...other}>
      {children || "class names"}
    </Button>
  );
};

PConstainedButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  PConstainedButton: PropTypes.string,
};

export default withStyles(styles)(PConstainedButton);
