import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import { useParams } from "react-router-dom";

import LogoComponent from "../../../components/auth/Logo.component";
import ResetPasswordComponent from "../../../components/auth/ResetPassword/ResetPassword.component";

import { ResetPasswordStyles } from "./ResetPassword.styles";

let urlCrypt = require("url-crypt")(
  '~{ry*I)==yU/]9<7DPk!Hj"R#:-/Z7(hTBnlRS=4CXF'
);

const ResetPassword = () => {
  let { id } = useParams();

  let userId = urlCrypt.decryptObj(id);
  console.log("id", userId.user_id);

  return (
    <div>
      <Grid container style={ResetPasswordStyles.lottieBackground}>
        <Hidden only="xs">
          <Grid item xs={12} sm={6}>
            <LogoComponent />
          </Grid>
        </Hidden>

        <Grid item xs={12} sm={6}>
          <Paper elevation={3}>
            <ResetPasswordComponent userId={userId.user_id} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ResetPassword;
