import React from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import CalendarIcon from "@material-ui/icons/CalendarTodayRounded";
import { IconButton } from "@material-ui/core";

const SearchAndFilter = (props) => {
  let { search, setSearch, startDate, setStartDate, endDate, setEndDate } =
    props;
  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-around" className="SearchItem">
          <Grid sm="1"></Grid>
          <Grid item sm="3" className="SearchItem">
            <TextField
              label="Search"
              type="text"
              variant="outlined"
              size="small"
              margin="dense"
              value={search}
              disabled={true}
              onChange={(event) => {
                setSearch(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    {search === "" ? (
                      <SearchIcon />
                    ) : (
                      <CloseIcon onClick={() => setSearch("")} />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item sm="3" className="SearchItem">
            <KeyboardDatePicker
              variant="inline"
              size="small"
              autoOk={true}
              margin="dense"
              value={startDate}
              inputVariant="outlined"
              inputMode="none"
              format="MM/dd/yyyy"
              label="Start Date"
              maxDate={new Date()}
              disabled={true}
              onChange={(date) => setStartDate(date)}
              InputProps={{
                // startAdornment: startDate && (
                //   <CloseIcon
                //     style={{ width: 16 }}
                //     onClick={() => setStartDate(null)}
                //   />
                // ),
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item sm="3" className="SearchItem">
            <KeyboardDatePicker
              variant="inline"
              size="small"
              autoOk={true}
              margin="dense"
              value={endDate}
              inputVariant="outlined"
              label="End Date"
              format="MM/dd/yyyy"
              minDate={startDate}
              maxDate={new Date()}
              disabled={true}
              onChange={(date) => setEndDate(date)}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          {/* <Grid
            sm="2"
            style={{ justifyItems: "center", alignItems: "flex-end" }}
          >
            <Typography
              style={{ alignSelf: "flex-end" }}
              onClick={() => {

                setStartDate(null);
                setEndDate(null);
              }}
            >
              Clear filters
            </Typography>
          </Grid> */}
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default SearchAndFilter;
