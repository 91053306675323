const COLORS = {
  primary: "#333F52",
  secondary: "#0EBBF7",
  success: "#09C199",
  error: "#E15656",
  oddTableBackground: "#0014ff1c",
  white: "#fff",
  positiveButton: "#283b54",
  backgroundColor: "#EFF9FF",
};

export { COLORS };
