import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";

import LogoComponent from "../../../components/auth/Logo.component";
import ForgetPasswordComponent from "../../../components/auth/ForgetPassword/ForgetPassword.component";

import { ForgotPasswordStyles } from "./ForgotPassword.styles";

const ForgetPassword = () => {
  return (
    <div>
      <Grid container style={ForgotPasswordStyles.lottieBackground}>
        <Grid item xs={4} sm={4} />
        <Grid item xs={4} sm={4}>
          <Paper elevation={0} style={ForgotPasswordStyles.contentBox}>
            <ForgetPasswordComponent />
          </Paper>
        </Grid>
        <Grid item xs={4} sm={4} />
      </Grid>
    </div>
  );
};

export default ForgetPassword;
