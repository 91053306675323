import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { CheckCircleOutlined, CheckCircle } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ConfirmationDialog from "../../../utils/UI_Elements/Confirmation_Dialog/ConfirmationDialog.Component";

import {
  makeStyles,
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";

import { LOGIN } from "../../../graphql/mutations/Mutations";

import { COLORS } from "../../../utils/colors";
import { ERROR } from "../../../utils/error";
import { ERROR_TYPES } from "../../../utils/errorTypes";
import { validEmail, validPassword } from "../../../utils/utils";
import { CONSTANTS } from "../../../utils/constants/Constants";
import { LABELS } from "../../../utils/constants/Labels";
import { authenticate } from "../../../utils/cookie";
import PContainedButton from "../../../utils/UI_Elements/PContainedButton";

import { LoginStyles } from "./Login.styles";
import SalLogo from "../../../assests/images/sal_84.png";

import isLoadingHOC from "../../../hoc/isLoadingHOC";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#304B77",
      borderRadius: 7,
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E15656",
      borderRadius: 7,
    },
    // "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    //   borderColor: "#3B5464",
    // },
    // "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    //   borderColor: "#3B5464",
    // },
    "& .MuiOutlinedInput-input": {
      color: "#304B77",
    },
    // "&:hover .MuiOutlinedInput-input": {
    //   color: "#3B5464",
    // },
    // "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
    //   color: "#3B5464",
    // },
    "& .MuiInputLabel-outlined": {
      color: "#304B77",
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#E15656",
    },
    // "&:hover .MuiInputLabel-outlined": {
    //   color: "#3B5464",
    // },
    // "& .MuiInputLabel-outlined.Mui-focused": {
    //   color: "#3B5464",
    // },
    "& .MuiIconButton-root": {
      color: "#B5E3FF",
    },
  },
});

export const Login = (props) => {
  const { setLoading } = props;
  const history = useHistory();
  const classes = useStyles();

  const [login, setLogin] = useState({
    email: "",
    password: "",
    // isRead: true,
    // email: CONSTANTS.EMPTY_STRING,
    // password: CONSTANTS.EMPTY_STRING,
    isRead: CONSTANTS.EMPTY_STRING,
    emailError: CONSTANTS.EMPTY_STRING,
    passwordError: CONSTANTS.EMPTY_STRING,
  });

  const [values, setValues] = useState({
    password: CONSTANTS.EMPTY_STRING,
    showPassword: false,
  });
  const [unableLogin, setUnableLogin] = useState(false);

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    validate("password", event.target.value);
  };

  const [fetchLogin, { loading, data }] = useMutation(LOGIN);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (data && data.login) {
      console.log(data);
      let errors = data.login.errors;
      if (errors) {
        if (errors[0].message === ERROR_TYPES.INVALID_EMAIL) {
          console.log(errors[0].message);
          setLogin({
            ...login,
            emailError: errors[0].message,
          });
        } else if (errors[0].message === ERROR_TYPES.INVALID_PASSWORD) {
          console.log(errors[0].message);
          setLogin({
            ...login,
            passwordError: ERROR_TYPES.INVALID_PASSWORD,
          });
        }
      } else {
        if (data.login.dealerUserId) {
          // console.log('login',data.login);
          props.handleLogin();
          authenticate(data.login, () => {
            return history.push({
              pathname: "/home",
              state: {
                userId: data.login.dealerUserId,
                dealershipName: data.login.dealership,
                prequalSubscription: data.login.subscriptions[1].is_enable,
                jwtToken: data.login.token,
              },
            });
          });
        } else {
          setLogin({
            ...login,
            passwordError: ERROR_TYPES.INVALID_PASSWORD,
          });
        }
      }
    }
  }, [data]);

  const loginHandler = () => {
    if (!validEmail(login.email)) {
      setLogin({
        ...login,
        emailError: ERROR.invalidEmail,
      });
    } else if (!validPassword(login.password)) {
      setLogin({
        ...login,
        passwordError: ERROR.invalidPassword,
      });
    } else if (!login.isRead) {
      setLogin({
        ...login,
        isRead: false,
      });
    } else if (login.isRead && login.isRead !== "") {
      fetchLogin({
        variables: {
          loginInput: {
            email: login.email,
            password: login.password,
          },
        },
      });
    }
  };

  const forgotPasswordHandler = (event) => {
    event.preventDefault();
    history.push("/forgotpassword");
  };

  const validate = (field, value) => {
    let error = CONSTANTS.EMPTY_STRING;
    // let error = " ";
    switch (field) {
      case "email":
        if (!validEmail(value)) {
          error = ERROR.invalidEmail;
        }
        if (value === CONSTANTS.EMPTY_STRING) {
          error = CONSTANTS.EMPTY_STRING;
        }
        setLogin({
          ...login,
          email: value,
          emailError: error,
        });
        break;
      case "password":
        if (!validPassword(value)) {
          error = ERROR.invalidPassword;
        }
        if (value === CONSTANTS.EMPTY_STRING) {
          error = CONSTANTS.EMPTY_STRING;
        }
        setLogin({
          ...login,
          password: value,
          passwordError: error,
        });
        break;
      case "isRead":
        setLogin({
          ...login,
          isRead: value,
        });
        break;
      default:
        break;
    }
  };

  return (
    <Box width={400} style={LoginStyles.loginBox}>
      <img
        src={SalLogo}
        alt="salesline-logo"
        width={84}
        style={{ marginBottom: 16, alignSelf: "center" }}
      />
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        style={{ color: "#304B77" }}
      >
        Login
      </Typography>
      <TextField
        // fullWidth
        label="Email Address"
        variant="outlined"
        size="medium"
        margin="normal"
        value={login.email}
        error={login.emailError}
        helperText={login.emailError}
        className={classes.root}
        onChange={(event) => {
          validate("email", event.target.value);
        }}
      />
      <TextField
        // fullWidth
        label="Password"
        type={values.showPassword ? "text" : "password"}
        variant="outlined"
        size="medium"
        margin="normal"
        value={values.password}
        error={login.passwordError}
        helperText={login.passwordError}
        onChange={handlePasswordChange("password")}
        className={classes.root}
        InputProps={{
          style: { padding: 0 },
          endAdornment: (
            <InputAdornment style={{ paddingRight: 0 }} position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <FormControlLabel
        style={LoginStyles.read}
        control={
          <Checkbox
            name="read"
            color="secondary"
            icon={<CheckCircleOutlined />}
            checkedIcon={<CheckCircle />}
            style={{
              color:
                login.isRead === true || login.isRead === ""
                  ? "#304B77"
                  : COLORS.error,
            }}
            onChange={(event) => {
              validate("isRead", event.target.checked);
            }}
          />
        }
        label={
          <Typography style={{ fontSize: 14, color: "#304B77" }}>
            {LABELS.TERMS_CONDITIONS}
          </Typography>
        }
      />

      <PContainedButton style={LoginStyles.loginButton} onClick={loginHandler}>
        Login
      </PContainedButton>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          marginBottom: 16,
        }}
      >
        <Typography
          style={LoginStyles.forgotButton}
          onClick={forgotPasswordHandler}
          gutterBottom
          align="left"
        >
          Forgot Password ?
        </Typography>

        <Typography
          style={LoginStyles.forgotButton}
          onClick={() => setUnableLogin(true)}
          gutterBottom
          align="left"
        >
          Unable to login ?
        </Typography>
      </div>
      <div style={{ marginBottom: "12%" }}>
        <Typography style={LoginStyles.disclosure} gutterBottom align="justify">
          {LABELS.LOGIN_WARNING}
        </Typography>
        <Typography style={LoginStyles.disclosure} gutterBottom align="justify">
          {LABELS.LOGIN_DISCLOSE}
        </Typography>
      </div>
      <ConfirmationDialog
        open={unableLogin}
        title="Assistance with logging onto dealerlinesolutions.com"
        message={
          <div>
            <p>
              Q: Do you have an email address that has been added to your
              dealership's profile?
            </p>
            <p>
              A: If not, please contact your administrator or email to{" "}
              <b>dealerlinesupport.com</b>. One will able to set your profile up
            </p>
            <p>
              Q: Are you getting an invalid email address / password error ?
            </p>
            <p>
              A: Please re-attempt to login. If you receive the same error, you
              will need to reset your password. You can do this by clicking the
              forget password button and follow instructions provided.
            </p>
          </div>
        }
        secondButtonLabel="Close"
        secondButtonFunction={() => {
          setUnableLogin(false);
        }}
        firstButtonLabel="Forget Password"
        firstButtonFunction={forgotPasswordHandler}
      />
    </Box>
  );
};

export default isLoadingHOC(Login);
