import { COLORS } from "../../../utils/colors";

export const FPStyles = {
  backToLoginButton: {
    alignSelf: "flex-end",
    color: "#304B77",
    cursor: "pointer",
    marginTop: 10,
  },
  forgetBox: {
    height: "100vh",
    backgroundColor: COLORS.backgroundColor,
  },
  forgetPasswordButton: {
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
  },
};
