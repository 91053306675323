import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import AccountCircleSharpIcon from "@material-ui/icons/AccountCircleSharp";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { useHistory } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

import InformationDialog from "../../../utils/UI_Elements/Information_Dialog/InformationDialog.Component";

import saleslineLogo from "../../../assests/images/sales-line.svg";
import dealerlineLogo from "../../../assests/images/dealer-line.svg";

import { isAuth, logout } from "../../../utils/cookie";

import "./Header.styles.css";

const Header = (props) => {
  const history = useHistory();
  const homeHandler = () => history.push("/home");
  const [showDialog, setShowDialog] = useState(false);

  const LogOutHandler = () => {
    setShowDialog(false);
    sessionStorage.clear();
    props.handleLogout();
    logout(history);
  };

  const onIdle = () => {
    if (isIdle) {
      setShowDialog(true);
    }
  };
  const {
    start,
    reset,
    pause,
    resume,
    isIdle,
    isPrompted,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 30,
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    syncTimers: 0,
  });

  return (
    <div className="Header">
      <AppBar position="static" className="AppBar" elevation={0}>
        <Toolbar>
          <img src={saleslineLogo} style={{ width: 50 }} alt="logo" />
          <Typography
            variant="h5"
            style={{ marginLeft: 10 }}
            className="SalesLine"
            onClick={homeHandler}
          >
            Sales Line
          </Typography>
          <div className="Setting">
            <AccountCircleSharpIcon className="MR10" />
            <Typography variant="h6" className="MR10">
              {}
              {sessionStorage.getItem("dealership_name")}
            </Typography>
            <PowerSettingsNewIcon className="Logout" onClick={LogOutHandler} />
          </div>
        </Toolbar>
      </AppBar>
      <InformationDialog
        open={showDialog}
        title="Alert"
        message="Your session ended due to inactivity. Please login again !"
        firstButtonLabel="OKAY"
        firstButtonFunction={() => {
          LogOutHandler();
        }}
      />
    </div>
  );
};
export default Header;
