import React, { useState, useEffect } from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import MUIDataTable from "mui-datatables";
import Paper from "@material-ui/core/Paper";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import moment from "moment-timezone";
import { useMutation, useQuery } from "@apollo/client";
import { useSubscription } from "@apollo/react-hooks";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import Tooltip from "@material-ui/core/Tooltip";
import ToolTip from "react-portal-tooltip";

import SearchAndFilter from "../SearchAndFilter/SearchAndFilter.component";
import CustomerDialog from "../CustomerDialog/CustomerDialog.component";
import VVDialog from "../VVDialog/VVDialog.component";
import YMMDialog from "../YMMDialog/YMMDialog.component";
import FICODialog from "../FICODialog/FICODialog.component";
import ComplianceDialog from "../ComplianceDialog/ComplianceDialog.component";

import ConfirmationDialog from "../../../utils/UI_Elements/Confirmation_Dialog/ConfirmationDialog.Component";
import InformationDialog from "../../../utils/UI_Elements/Information_Dialog/InformationDialog.Component";

import {
  RECALL_PREQUAL,
  CALL_PREQUAL,
  GET_VEHICLE_IDS,
  TRIGGER_SALESLINE_MAIN_GRID,
  UPDATE_VEHICLE_SOLD,
  UPDATE_FBGROSS,
  GENERATE_TRADEPENDING_REPORT,
  GENERATE_COMPLIANCE_REPORT,
} from "../../../graphql/mutations/Mutations";
import {
  ON_SAVE_CUSTOMER,
  ON_SAVE_VEHICLE,
  ON_CUSTOMER_VEHICLE_SAVE,
  ON_PREQUAL_CALL,
  ON_VEHICLE_VALUATION,
  ON_VEHICLE_SOLD_CHANGE,
  ON_SAVE_CUSTOMER_SL,
  ON_SAVE_VEHICLE_SL,
  ON_PARTIAL_SAVE_CUSTOMER_SL,
  ON_COMPLETE_TRANSACTION,
  ON_RECORD_DEALERSHIP_SALES,
  ON_FB_GROSS_CHANGE,
  ON_INSERT_CUSTOMER,
  ON_INSERT_VEHICLE,
} from "../../../graphql/subscriptions/Subscriptions";

import "./MainGrid.styles.css";
import { COLORS } from "../../../utils/colors";

import TablePagination from "@material-ui/core/TablePagination";

import isLoadingHOC from "../../../hoc/isLoadingHOC";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: 80,
  },

  menu: {
    width: 100,
  },
}));

const PREQUAL_CUSTOMER = {
  _user_id: "",
  _first_name: "",
  _middle_name: "",
  _last_name: "",
  _street: "",
  _city: "",
  _state: "",
  _zipcode: "",
  _transactionId: "",
};

const MainGrid = (props) => {
  const { setLoading } = props;

  const [customerDialogIsOpen, setCustomerDialogIsOpen] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [VVDialogOpenIsOpen, setVVDialogOpenIsOpen] = useState(false);
  const [YMMDialogOpenIsOpen, setYMMDialogOpenIsOpen] = useState(false);
  const [complianceDialogOpenIsOpen, setComplianceDialogOpenIsOpen] =
    useState(false);
  const [ymmm, setYMMM] = useState(null);
  const [FICODialogOpenIsOpen, setFICODialogOpenIsOpen] = useState(false);
  const [ficoHtml, setFicoHtml] = useState(null);
  const [search, setSearch] = useState("");
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [VVReport, setVVReport] = useState("");
  const [complianceReport, setComplianceReport] = useState("");
  const [page, setPage] = useState(0);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [prequalCustomer, setPrequalCustomer] = useState(PREQUAL_CUSTOMER);
  const [showPrequalAck, setPrequalAck] = useState(false);
  const [showPrequalInputError, setPrequalInputError] = useState(false);
  const [showNotSubscribedError, setShowNotSubscribedError] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [dataSize, setDataSize] = useState(0);
  const [rowIndex, setRowIndex] = useState(null);

  const [showToolTip, setShowToolTip] = useState(false);

  let fbGross = 0;

  //Handler to move forward and backward between pages and load more data

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const today = moment(new Date()).format("MM/DD/YYYY");

  const [fetchVehicleIds, { loading: getVehicleIdsLoading, data: vehicleIds }] =
    useMutation(GET_VEHICLE_IDS);
  const [recallPrequal, { loading: recallLoading, data: recallResponse }] =
    useMutation(RECALL_PREQUAL);
  const [fetchReport, { loading: loadingReport, data: htmlReport }] =
    useMutation(GENERATE_TRADEPENDING_REPORT);
  const [
    fetchComplianceReport,
    { loading: loadingComplianceReport, data: complianceHtmlReport },
  ] = useMutation(GENERATE_COMPLIANCE_REPORT);
  const {
    loading: loadingMainGrid,
    error: MainGridError,
    data,
    refetch,
  } = useQuery(TRIGGER_SALESLINE_MAIN_GRID, {
    fetchPolicy: "cache-and-network",
    variables: {
      dealerCustomerInput: {
        dealerUserId: sessionStorage.getItem("user_id"),
        pageCount: page,
        rowsPerPage: rowsPerPage,
        searchText: search,
        startDate: startDate,
        endDate: endDate,
      },
    },
  });

  //Define the subscription that captures real time data of logged in user
  const { data: onSaveCustomer } = useSubscription(ON_SAVE_CUSTOMER);
  const { data: onSaveVehicle } = useSubscription(ON_SAVE_VEHICLE);
  const { data: onCustomerVehicleSave, loading: customerVehicleLoading } =
    useSubscription(ON_CUSTOMER_VEHICLE_SAVE);
  const { data: onPrequalCall } = useSubscription(ON_PREQUAL_CALL);
  const { data: onVehicleValuation } = useSubscription(ON_VEHICLE_VALUATION);
  const { data: onVehicleSoldChange } = useSubscription(ON_VEHICLE_SOLD_CHANGE);
  const { data: onFBgrossUpdate, loading: onFbGrossLoading } =
    useSubscription(ON_FB_GROSS_CHANGE);
  const { data: onSaveCustomerSL } = useSubscription(ON_SAVE_CUSTOMER_SL);
  const { data: onPartialSaveCustomer } = useSubscription(
    ON_PARTIAL_SAVE_CUSTOMER_SL
  );
  const { data: onSaveVehicleSL } = useSubscription(ON_SAVE_VEHICLE_SL);
  const { data: onCompleteTransaction } = useSubscription(
    ON_COMPLETE_TRANSACTION
  );
  const { data: onRecordDealershipSales } = useSubscription(
    ON_RECORD_DEALERSHIP_SALES
  );
  const { data: onInsertCustomer } = useSubscription(ON_INSERT_CUSTOMER);
  const { data: onInsertVehicle } = useSubscription(ON_INSERT_VEHICLE);

  const [updateFBGross, { loading: fbGrossLoading, data: fbGrossResponse }] =
    useMutation(UPDATE_FBGROSS);

  const [initiatePrequal, { loading: prequalLoading, data: prequalResponse }] =
    useMutation(CALL_PREQUAL);

  useEffect(() => {
    refetch({
      dealerCustomerInput: {
        dealerUserId: sessionStorage.getItem("user_id"),
        pageCount: page,
        rowsPerPage: rowsPerPage,
        searchText: search,
        startDate: startDate,
        endDate: endDate,
      },
    });
  }, [
    onSaveCustomer,
    onSaveVehicle,
    onCustomerVehicleSave,
    onPrequalCall,
    onVehicleValuation,
    onVehicleSoldChange,
    onFBgrossUpdate,
    onSaveCustomerSL,
    onPartialSaveCustomer,
    onSaveVehicleSL,
    onCompleteTransaction,
    onRecordDealershipSales,
    onInsertCustomer,
    onInsertVehicle,
    page,
    rowsPerPage,
  ]);

  useEffect(() => {
    if (data) {
      console.log("data: ", data);
      let errors = data.errors;
      if (errors) {
        console.log("Error: ", data.errors);
      } else {
        setTotalRecordsCount(data.getDealerCustomer.totalRecordsCount);
        if (data.getDealerCustomer.details) {
          setFilteredRecords(data.getDealerCustomer.details);
        } else {
          setFilteredRecords([]);
        }

        // setDataSize(data.getDealerCustomer.details.length);
        // console.log("data", data);
      }
    }
  }, [data]);

  const recallPrequalHandler = async (customerId) => {
    await recallPrequal({
      variables: {
        recallPrequalInput: {
          customerId: customerId,
        },
      },
    });
  };

  const tradePendingReport = async (vehicleId, zipcode, mileage) => {
    await fetchReport({
      variables: {
        tradePendingInput: {
          vin: vehicleId,
          zipCode: zipcode,
          mileage: mileage,
        },
      },
    });
  };

  React.useEffect(() => {
    if (htmlReport) {
      console.log("html", htmlReport);
      setVVReport(htmlReport.generateTradePendingReport.report);
      setVVDialogOpenIsOpen(true);
    }
  }, [htmlReport]);

  const complianceReportHandler = async (userId, dealerUserId) => {
    console.log("user", userId);
    await fetchComplianceReport({
      variables: {
        complianceReportInput: {
          userId: userId,
          vehicleId: "",
          agentId: "",
          dealerUserId: dealerUserId,
        },
      },
    });
  };
  React.useEffect(() => {
    if (complianceHtmlReport) {
      setComplianceReport(
        complianceHtmlReport.generateComplianceReport.complianceReport
      );
      // setComplianceDialogOpenIsOpen(true);
      var tab = window.open(
        "about:blank",
        "_blank",
        `width=${window.innerWidth * 0.9},height=${
          window.innerHeight * 0.9
        },top=50%,left=50%,toolbar=no,location=no,status=no,menubar=no,resizable=no,`
      );
      tab.document.write(
        complianceHtmlReport.generateComplianceReport.complianceReport
      );
      tab.document.close();
    }
  }, [complianceHtmlReport]);

  React.useEffect(() => {
    if (recallResponse) {
      setFicoHtml(recallResponse.recallPrequal.htmlResponse);
      setFICODialogOpenIsOpen(true);
    }
  }, [recallResponse]);

  const callPrequal = async () => {
    await initiatePrequal({
      variables: {
        preQualInput: {
          vehicleId: null,
          dealerUserId: null,
          agentId: sessionStorage.getItem("user_id"),
          userId: prequalCustomer._user_id,
          firstName: prequalCustomer._first_name,
          middleName:
            prequalCustomer._middle_name === "N/A"
              ? ""
              : prequalCustomer._middle_name,
          lastName: prequalCustomer._last_name,
          streetAddress: prequalCustomer._street,
          city: prequalCustomer._city,
          state: prequalCustomer._state,
          zipcode: prequalCustomer._zipcode,
          origin: 3,
          vin: filteredRecords[rowIndex].vehicle.vin || "",
          transactionId: prequalCustomer._transactionId,
        },
      },
    });
  };

  useEffect(() => {
    if (prequalResponse) {
      // console.log("pre", prequalResponse);
    }
  }, [prequalResponse]);

  useEffect(() => {
    if (fbGrossResponse) {
      console.log("fb", fbGrossResponse);
    }
  }, [fbGrossResponse]);

  const updateFBGrossHandler = async (
    customerId,
    vehicleId,
    value,
    transactionId
  ) => {
    await updateFBGross({
      variables: {
        fbGrossInput: {
          customerUserId: customerId,
          vehicleId: vehicleId,
          paymentId: "",
          agentUserId: sessionStorage.getItem("user_id"),
          fbGrossValue: parseInt(value, 10),
          transactionId: transactionId,
        },
      },
    });
  };

  useEffect(() => {
    const enterKeyPressHandler = (event) => {
      if (event.keyCode === 13 && search) {
        if (dataSize < 30) {
          setPage(0);
          refetch({
            dealerCustomerInput: {
              dealerUserId: sessionStorage.getItem("user_id"),
              pageCount: 0,
              rowsPerPage: rowsPerPage,
              searchText: search,
              startDate: startDate,
              endDate: endDate,
            },
          });
        }
      }
    };
    window.addEventListener("keydown", enterKeyPressHandler);

    return () => {
      window.removeEventListener("keydown", enterKeyPressHandler);
    };
  }, [search]);

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MuiSelect: { outlined: { width: "70px", textAlign: "start" } },
        MuiOutlinedInput: {
          root: {
            height: "20px",
          },
          input: { width: "60px", height: "20px" },
        },
        MuiInputBase: {
          root: {
            height: "20px",
            "&$disabled": {
              background: "#ddd",
            },
          },
          formControl: {
            height: "20px",
          },
        },
        MuiTextField: {
          root: {
            height: "20px",
            margin: "0px",
          },
        },
        MuiInputLabel: {
          formControl: {
            position: "none",
          },
        },
        MUIDataTableFilter: {
          gridListTile: {
            marginTop: 0,
          },
          root: {
            marginBottom: "30px",
          },
          resetLink: {
            display: "none",
          },
        },
        MUIDataTable: {
          responsiveScrollMaxHeight: {
            border: "1px solid #283b54",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          },
        },
        MUIDataTableToolbar: {
          root: {
            // display: "none",
          },
          left: {
            flexGrow: 0.2,
            flexShrink: 0,
            flexBasis: "auto",
          },
          actions: {
            display: "flex",
            flex: "1 1 auto",
            justifyContent: "flex-end",
            flexDirection: "row",
            // move all icons to the right
            "& > span, & > button": {
              order: 10,
            },
            // target the custom toolbar icon
            "& > span:last-child, & > button:last-child": {
              order: 1,
            },
            // target any icon
            "& > span:nth-child(4), & > button:nth-child(4)": {
              order: 2,
            },
          },
        },
        MuiTableHead: {
          root: {
            background: "#283b54",
            position: "relative",
          },
        },
        MuiTableCell: {
          root: {
            padding: "0px",
            height: "10px",
            borderBottom: "none",
          },
          head: {
            color: "white",
            textAlign: "center",
            justifyContent: "center",
            position: "fixed",
            background: "#283b54",
          },
          body: {
            textAlign: "center",
          },
        },
        MuiTableRow: {
          root: {
            "&$hover:hover": {
              backgroundColor: "none",
            },
          },
        },
        MuiButton: {
          root: {
            color: "white",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(even)": {
              background: "rgba(0,161,255,0.15)",
            },
            "&:nth-child(odd)": {
              background: "white",
            },
          },
        },
        MUIDataTableHeadCell: {
          fixedHeader: {
            backgroundColor:
              "linear-gradient( to right, #212a38, #283b54, #2c4d71, #2d5f90, #2973b0 ) !important",
            zIndex: 10,
          },

          sortActive: {
            color: "white",
          },
          toolButton: {
            justifyContent: "center",
          },
          contentWrapper: {
            justifyContent: "center",
          },
        },
        MuiTableSortLabel: {
          icon: {
            color: "white !important",
          },
          "&$hover:hover": {
            color: "white !important",
          },
        },
        MuiIconButton: {
          root: {
            marginBottom: "16px",
          },
        },
        MUIDataTableFilterList: {
          chip: {
            margin: "8px",
            background: "rgba(0,161,255,0.25)",
          },
        },
        MuiChip: {
          deleteIcon: {
            color: "#8fadcb",
            "&:hover": {
              color: "#f17272 !important",
            },
          },
        },
        MUIDataTableSearch: {
          searchText: {
            marginTop: "12px",
          },
        },
      },
    });

  const columns = [
    {
      name: "date",
      label: "DateIN",
      options: {
        sort: true,
        sortThirdClickReset: true,
        searchable: false,
        filter: false,
        filterType: "multiselect",
        customBodyRender: (date, _tableMeta, _updateValue) => {
          return moment
            .utc(date, "YYYY-MM-DDThh:mm:ss")
            .tz("America/Detroit")
            .format("MM/DD/YY hh:mm a");
        },
      },
    },
    {
      name: "serviceAgentName",
      label: "Employee",
      options: {
        sort: true,
        sortThirdClickReset: true,
        filter: true,
        filterType: "multiselect",
        searchable: false,
      },
    },
    {
      name: "customer",
      label: "Customer",
      options: {
        sort: false,
        filter: false,
        filterType: "multiselect",
        searchable: true,
        customBodyRender: (customer, _tableMeta, _updateValue) => {
          return customer.last_name === "N/A" ? (
            "N/A"
          ) : (
            <div className="hyperlink">{customer.last_name}</div>
          );
        },
      },
    },
    {
      name: "vehicle",
      label: "YMM",
      options: {
        sort: false,
        filter: false,
        filterType: "multiselect",
        searchable: false,
        customBodyRender: (vehicle, _tableMeta, _updateValue) => {
          return vehicle.make_year === "N/A" ? (
            "N/A"
          ) : (
            <div className="hyperlink">
              {vehicle.make_year + " " + vehicle.make + " " + vehicle.model}
            </div>
          );
        },
      },
    },
    {
      name: "vehicleValue",
      label: "VV",
      options: {
        sort: true,
        sortThirdClickReset: true,
        filter: true,
        filterType: "multiselect",
        searchable: false,
        customBodyRender: (vehicleValue, _tableMeta, _updateValue) => {
          return vehicleValue === "N/A" ? (
            "N/A"
          ) : (
            <div className="hyperlink">
              $ {parseInt(vehicleValue, 10).toLocaleString("en-US")}
            </div>
          );
        },
      },
    },
    {
      name: "payoff",
      label: "Payoff",
      options: {
        sort: true,
        sortThirdClickReset: true,
        filter: true,
        filterType: "multiselect",
        searchable: false,
        customBodyRender: (payoff, _tableMeta, _updateValue) => {
          return payoff === "N/A"
            ? "N/A"
            : payoff === "0"
            ? "N/A"
            : "$ " + parseInt(payoff, 10).toLocaleString("en-US");
        },
      },
    },
    {
      name: "equity",
      label: "Equity",
      options: {
        sort: true,
        sortThirdClickReset: true,
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          renderValue: (v) => v.replace("-", ""),
        },
        searchable: false,
        customBodyRender: (value, _tableMeta, _updateValue) => {
          if (value !== "N/A") {
            if (Math.sign(parseInt(value, 10)) === -1) {
              return (
                <p style={{ color: "red", margin: 0 }}>
                  {"$ " + parseInt(value, 10).toLocaleString("en-US")}
                </p>
              );
            } else if (Math.sign(parseInt(value, 10)) === 1) {
              return (
                <p style={{ margin: 0 }}>
                  {"$ " + parseInt(value, 10).toLocaleString("en-US")}
                </p>
              );
            }
          } else {
            return "N/A";
          }
        },
      },
    },
    {
      name: "fico",
      label: "FICO",
      options: {
        sort: true,
        sortThirdClickReset: true,
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          renderValue: (v) => v.toLocaleString(),
        },
        searchable: false,
        customBodyRender: (fico, _tableMeta, _updateValue) => {
          let expiryFlag;
          for (let object of filteredRecords) {
            if (object.customer) {
              if (
                object.customer.user_id &&
                _tableMeta.currentTableData[_tableMeta.rowIndex].data[2]
                  .user_id &&
                object.customer.user_id ===
                  _tableMeta.currentTableData[_tableMeta.rowIndex].data[2]
                    .user_id
              ) {
                expiryFlag = object.prequalExpiryFlag;
                break;
              }
            }
          }
          return fico > 0 ? (
            expiryFlag === "0" ? (
              <div className="hyperlink">{fico}</div>
            ) : (
              fico
            )
          ) : (
            <Button
              style={{
                height: 18,
                fontSize: 11,
                background: "#0EBBF7",
                zIndex: 0,
                borderRadius: "inherit",
                // marginTop: 4,
                // marginBottom: 4,
              }}
            >
              Run prequal
            </Button>
          );
        },
      },
    },
    {
      name: "compliance",
      label: "Compliance",
      options: {
        sort: false,
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          renderValue: (v) => {
            switch (v) {
              case "N/A":
                return "N/A";
              case "red":
                return (
                  <svg height="18" width="18">
                    <circle cx="8" cy="8" r="8" stroke-width="1" fill="red" />
                  </svg>
                );
              case "yellow":
                return (
                  <svg height="18" width="18">
                    <circle
                      cx="8"
                      cy="8"
                      r="8"
                      stroke-width="1"
                      fill="yellow"
                    />
                  </svg>
                );
              case "green":
                return (
                  <svg height="18" width="18">
                    <circle cx="8" cy="8" r="8" stroke-width="1" fill="green" />
                  </svg>
                );
              default:
            }
          },
        },
        searchable: false,
        customBodyRender: (value, _tableMeta, _updateValue) => {
          let complianceActivities = `PreQual Consent\nHard Consent\nRF WrkSht\nPrivacy Notice\nAA Notice\nRBP\nOFAC`;
          let complianceStatus;
          let separator = ` - \n - \n - \n - \n - \n - \n - \n`;

          let requiredFlags =
            filteredRecords[_tableMeta.rowIndex].requiredFlags;
          let completedFlags =
            filteredRecords[_tableMeta.rowIndex].completedFlags;

          if (requiredFlags && completedFlags) {
            requiredFlags = parseInt(requiredFlags).toString(2);
            completedFlags = parseInt(completedFlags).toString(2);

            while (requiredFlags.length < 13) {
              requiredFlags = "0" + requiredFlags;
            }
            while (completedFlags.length < 13) {
              completedFlags = "0" + completedFlags;
            }
            const completedArrStrings = completedFlags.split("");

            if (completedArrStrings.length > 0) {
              if (completedArrStrings[1] === "1") {
                complianceStatus = `Completed`;
              } else {
                complianceStatus = `Not Completed`;
              }

              if (completedArrStrings[6] === "1") {
                complianceStatus = complianceStatus + `\nCompleted`;
              } else {
                complianceStatus = complianceStatus + `\nNot Completed`;
              }

              if (completedArrStrings[7] === "1") {
                complianceStatus = complianceStatus + `\nCompleted`;
              } else {
                complianceStatus = complianceStatus + `\nNot Completed`;
              }

              if (completedArrStrings[8] === "1") {
                complianceStatus = complianceStatus + `\nCompleted`;
              } else {
                complianceStatus = complianceStatus + `\nNot Completed`;
              }

              if (completedArrStrings[9] === "1") {
                complianceStatus = complianceStatus + `\nCompleted`;
              } else {
                complianceStatus = complianceStatus + `\nNot Completed`;
              }

              if (completedArrStrings[10] === "1") {
                complianceStatus = complianceStatus + `\nCompleted`;
              } else {
                complianceStatus = complianceStatus + `\nNot Completed`;
              }

              if (completedArrStrings[11] === "1") {
                complianceStatus = complianceStatus + `\nCompleted`;
              } else {
                complianceStatus = complianceStatus + `\nNot Completed`;
              }
            }
          }

          if (filteredRecords[_tableMeta.rowIndex].fico <= 0) {
            return "N/A";
          } else if (
            _tableMeta.currentTableData[_tableMeta.rowIndex].data[7] > 0 &&
            value === "N/A"
          ) {
            return (
              <svg height="12" width="12">
                <circle cx="6" cy="6" r="6" stroke-width="1" fill="#09C199" />
              </svg>
            );
          } else {
            if (value === "green") {
              return (
                <Tooltip
                  arrow
                  title={
                    <div
                      style={{
                        whiteSpace: "pre-line",
                        display: "flex",
                        flex: 3,
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ textAlign: "left" }}>
                        {complianceActivities}
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          marginRight: 4,
                          marginLeft: 4,
                        }}
                      >
                        {separator}
                      </div>
                      <div style={{ textAlign: "right" }}>
                        {`Completed\nCompleted\nCompleted\nCompleted\nCompleted\nCompleted\nCompleted`}
                      </div>
                      {/* {`PreQual Consent - Completed\nHard Consent - Completed\nRF WrkSht - Completed\nPrivacy Notice - Completed\nAA Notice - Completed\nRBP - Completed\nOFAC - Completed`} */}
                    </div>
                  }
                  placement="right"
                >
                  <svg height="12" width="12">
                    <circle
                      cx="6"
                      cy="6"
                      r="6"
                      stroke-width="1"
                      fill="#09C199"
                    />
                  </svg>
                </Tooltip>
              );
            } else if (value === "yellow") {
              return (
                <Tooltip
                  arrow
                  title={
                    <div
                      style={{
                        whiteSpace: "pre-line",
                        display: "flex",
                        flex: 3,
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ textAlign: "left" }}>
                        {complianceActivities}
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          marginRight: 4,
                          marginLeft: 4,
                        }}
                      >
                        {separator}
                      </div>
                      <div style={{ textAlign: "right" }}>
                        {complianceStatus}
                      </div>
                    </div>
                  }
                  placement="right"
                >
                  <svg height="12" width="12">
                    <circle
                      cx="6"
                      cy="6"
                      r="6"
                      stroke-width="1"
                      fill="#D7D700"
                    />
                  </svg>
                </Tooltip>
              );
            } else if (value === "red") {
              return (
                <Tooltip
                  arrow
                  title={
                    <div
                      style={{
                        whiteSpace: "pre-line",
                        display: "flex",
                        flex: 3,
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ textAlign: "left" }}>
                        {complianceActivities}
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          marginRight: 4,
                          marginLeft: 4,
                        }}
                      >
                        {separator}
                      </div>
                      <div style={{ textAlign: "right" }}>
                        {complianceStatus}
                      </div>
                      {/* {`PreQual Consent - Not Completed\nHard Consent - Not Completed\nRF WrkSht - Not Completed\nPrivacy Notice - Not Completed\nAA Notice - Not Completed\nRBP - Not Completed\nOFAC - Not Completed`} */}
                    </div>
                  }
                  placement="right"
                >
                  <svg height="12" width="12">
                    <circle
                      cx="6"
                      cy="6"
                      r="6"
                      stroke-width="1"
                      fill="#E15656"
                    />
                  </svg>
                </Tooltip>
              );
            } else {
              return "N/A";
            }
          }
        },
      },
    },

    {
      name: "offerAfter",
      label: "Offer After",
      options: {
        sort: false,
        filter: true,
        filterType: "multiselect",
        searchable: false,
      },
    },
    {
      name: "soldAfter",
      label: "Sold After",
      options: {
        sort: true,
        sortThirdClickReset: true,
        filter: true,
        filterType: "multiselect",
        searchable: false,
      },
    },
    {
      name: "afterMarketGross",
      label: "Aftmkt Gross",
      options: {
        sort: true,
        sortThirdClickReset: true,
        filter: true,
        filterType: "multiselect",
        searchable: false,
        customBodyRender: (aftMktGross, _tableMeta, _updateValue) => {
          return aftMktGross === "N/A"
            ? "N/A"
            : "$ " + parseInt(aftMktGross, 10).toLocaleString("en-US");
        },
      },
    },
    {
      name: "fbGross",
      label: "F&B Gross",
      options: {
        sort: true,
        sortThirdClickReset: true,
        filter: true,
        filterType: "multiselect",
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TextField
              type="number"
              variant="outlined"
              size="small"
              margin="normal"
              style={{ marginTop: 3, marginBottom: 3 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              value={value || ""}
              onChange={(event) => {
                console.log("state", filteredRecords[tableMeta.rowIndex]);
                console.log("index", tableMeta.rowData);
                updateValue(event.target.value);

                let updatedObject = filteredRecords[tableMeta.rowIndex];
                updatedObject = JSON.parse(JSON.stringify(updatedObject));
                updatedObject.fbGross = event.target.value;
                const newFilteredRecords = [
                  ...filteredRecords.slice(0, tableMeta.rowIndex),
                  updatedObject,
                  ...filteredRecords.slice(tableMeta.rowIndex + 1),
                ];
                setFilteredRecords(newFilteredRecords);
              }}
              onBlur={() => {
                console.log(
                  "dd",
                  tableMeta.currentTableData[tableMeta.rowIndex]
                );
                updateFBGrossHandler(
                  tableMeta.currentTableData[tableMeta.rowIndex].data[2]
                    .user_id,
                  tableMeta.currentTableData[tableMeta.rowIndex].data[3]
                    .vehicle_id,
                  value,
                  tableMeta.currentTableData[tableMeta.rowIndex].data[15]
                );
              }}
            />
          );
        },
      },
    },
    {
      name: "Start Date",
      options: {
        filter: true,
        display: false,
        searchable: false,
        type: "date",
        filterType: "custom",
        customFilterListRender: (v) => {
          if (v[0]) {
            return `Start Date: ${v[0]}`;
          }
          return false;
        },
        customFilterListOptions: {
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
              setSelectedStartDate("");
              setStartDate("");
            }
            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(_date, _filters) {
            return false;
          },
          display: (filterList, onChange, index, columns) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                label="Start Date"
                value={selectedStartDate}
                error={false}
                helperText={""}
                maxDate={new Date()}
                autoOk={true}
                onChange={(date) => {
                  filterList[index][0] = moment(new Date(date)).format(
                    "yyyy-MM-DD"
                  );
                  setSelectedStartDate(filterList[index][0]);
                  onChange(filterList[index], index, columns);
                  setStartDate(moment(new Date(date)).format("yyyy/MM/DD"));
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MuiPickersUtilsProvider>
          ),
        },
      },
    },
    {
      name: "End Date",
      options: {
        filter: true,
        display: false,
        searchable: false,
        type: "date",
        filterType: "custom",
        customFilterListRender: (v) => {
          if (v[0]) {
            return `End Date: ${v[0]}`;
          }

          setSelectedEndDate("");
          setEndDate("");
          return false;
        },
        customFilterListOptions: {
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
              setSelectedEndDate("");
              setEndDate("");
            }
            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(_date, _filters) {
            return false;
          },
          display: (filterList, onChange, index, columns) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                label="End Date"
                value={selectedEndDate}
                error={false}
                helperText={""}
                minDate={selectedStartDate}
                maxDate={new Date()}
                disabled={selectedStartDate.length < 1}
                autoOk={true}
                onChange={(date) => {
                  filterList[index][0] = moment(new Date(date)).format(
                    "yyyy-MM-DD"
                  );
                  setSelectedEndDate(filterList[index][0]);

                  onChange(filterList[index], index, columns);
                  setEndDate(moment(new Date(date)).format("yyyy/MM/DD"));
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MuiPickersUtilsProvider>
          ),
        },
      },
    },
    {
      name: "transactionId",
      label: "Transaction Id",
      options: {
        display:false,
        filter: false,
        searchable:false,
        customBodyRender: (transactionId, _tableMeta, _updateValue) => {
          return transactionId;
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    confirmFilters: true,
    responsive: "scrollMaxHeight",
    caseSensitive: false,
    search: true,
    elevation: 0,
    selectableRowsHeader: false,
    selectableRows: "none",
    print: false,
    download: false,
    viewColumns: false,
    pagination: false,
    fixedHeader: true,
    customSearch: (searchQuery, currentRow, columns) => {
      let isFound = false;
      columns.forEach((col, i) => {
        if (col.searchable) {
          if (
            currentRow[i].last_name
              .toString()
              .toLowerCase()
              .indexOf(searchQuery) >= 0 ||
            currentRow[i].last_name
              .toString()
              .toUpperCase()
              .indexOf(searchQuery) >= 0
          ) {
            isFound = true;
          }
        }
      });
      return isFound;
    },
    onCellClick: (colData, { colIndex, rowIndex, dataIndex }) => {
      if (colIndex === 2) {
        if (filteredRecords[dataIndex].customer.last_name !== "N/A") {
          setCustomerDialogIsOpen(true);
          setCustomer(filteredRecords[dataIndex].customer);
        }
      } else if (colIndex === 4) {
        if (filteredRecords[dataIndex].vehicleValue !== "N/A") {
          tradePendingReport(
            filteredRecords[dataIndex].vehicleUniqueId,
            filteredRecords[dataIndex].customer.zipcode,
            filteredRecords[dataIndex].vehicle.miles
          );
        }
      } else if (colIndex === 3) {
        if (filteredRecords[dataIndex].vehicle.vin !== "N/A") {
          setYMMDialogOpenIsOpen(true);
          setYMMM(filteredRecords[dataIndex].vehicle);
        }
      } else if (colIndex === 7) {
        console.log("fico", filteredRecords[dataIndex].fico);
        setRowIndex(dataIndex);
        if (sessionStorage.getItem("prequal_subscription")) {
          if (
            filteredRecords[dataIndex].customer.first_name !== "N/A" &&
            filteredRecords[dataIndex].customer.last_name !== "N/A" &&
            filteredRecords[dataIndex].customer.street !== "N/A" &&
            filteredRecords[dataIndex].customer.state !== "N/A" &&
            filteredRecords[dataIndex].customer.city !== "N/A" &&
            filteredRecords[dataIndex].customer.zipcode !== "N/A"
          ) {
            if (filteredRecords[dataIndex].fico !== "N/A") {
              if (filteredRecords[dataIndex].prequalExpiryFlag === "0")
                recallPrequalHandler(
                  filteredRecords[dataIndex].customer.user_id
                );
            } else {
              setPrequalAck(true);
              setPrequalCustomer({
                ...prequalCustomer,
                _user_id: filteredRecords[dataIndex].customer.user_id,
                _first_name: filteredRecords[dataIndex].customer.first_name,
                _middle_name: filteredRecords[dataIndex].customer.middle_name,
                _last_name: filteredRecords[dataIndex].customer.last_name,
                _street: filteredRecords[dataIndex].customer.street,
                _city: filteredRecords[dataIndex].customer.city,
                _state: filteredRecords[dataIndex].customer.state,
                _zipcode: filteredRecords[dataIndex].customer.zipcode,
                _transactionId: filteredRecords[dataIndex].transactionId,
              });
            }
          } else {
            setPrequalInputError(true);
          }
        } else {
          setShowNotSubscribedError(true);
        }
      } else if (colIndex === 8) {
        if (filteredRecords[dataIndex].fico !== "N/A") {
          complianceReportHandler(
            filteredRecords[dataIndex].customer.user_id,
            sessionStorage.getItem("user_id")
          );
        }
      }
    },
    onTableChange: (action, tableState) => {
      // console.log("tableChange", tableState);
      setDataSize(tableState.displayData.length);
      switch (action) {
        case "filterChange": {
          console.log("filter change");
          setSelectedStartDate("");
          setSelectedEndDate("");
          // setStartDate("");
          // setEndDate("");
          // refetch();
          break;
        }
        default:
          break;
      }
    },
    onSearchChange: (searchText) => {
      if (searchText) setSearch(searchText);
    },
    onSearchClose: () => {
      setSearch("");
      refetch({
        dealerCustomerInput: {
          dealerUserId: sessionStorage.getItem("user_id"),
          pageCount: page,
          rowsPerPage: rowsPerPage,
          searchText: "",
          startDate: "",
          endDate: "",
        },
      });
    },

    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div
          style={{
            marginTop: "60px",
            flex: "auto",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            style={{ background: "#0EBBF7", borderRadius: "inherit" }}
            onClick={() => {
              // handleFilterSubmit(applyNewFilters);
              applyNewFilters();
              // refetch({
              //   dealerCustomerInput: {
              //     dealerUserId: sessionStorage.getItem("user_id"),
              //     pageCount: 0,
              //     rowsPerPage: rowsPerPage,
              //     searchText: search,
              //     startDate: startDate,
              //     endDate: endDate,
              //   },
              // });
            }}
          >
            Apply Filters
          </Button>
        </div>
      );
    },
    onFilterChange: (column, filterList, type) => {
      console.log("filterlist", filterList);

      if (type === "chip") {
        var newFilters = () => filterList;
        handleFilterSubmit(newFilters);
      }
    },
    customToolbar: () => {
      return (
        <Tooltip title="Refresh">
          <IconButton
            onClick={() => {
              setPage(0);
              refetch({
                dealerCustomerInput: {
                  dealerUserId: sessionStorage.getItem("user_id"),
                  pageCount: 0,
                  rowsPerPage: rowsPerPage,
                  searchText: search,
                  startDate: startDate,
                  endDate: endDate,
                },
              });
            }}
          >
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onConfirmFilter: (filterList) => {
      console.log("filterlist", filterList);
    },
  };
  const handleFilterSubmit = (applyFilters) => {
    let filterList = applyFilters();
  };

  /*============== API call Loading ====================*/

  // React.useEffect(() => {
  //   setLoading(fbGrossLoading);
  // }, [fbGrossLoading]);

  React.useEffect(() => {
    setLoading(recallLoading);
  }, [recallLoading]);

  React.useEffect(() => {
    setLoading(loadingReport);
  }, [loadingReport]);

  // React.useEffect(() => {
  //   setLoading(loadingMainGrid);
  // }, [loadingMainGrid]);

  /*=====================================================*/
  return (
    <div>
      <Paper elevation={1} variant="outlined" className="SLGridPaper">
        {filteredRecords === null ? (
          <p>Loading</p>
        ) : (
          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={""}
              data={filteredRecords}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
        )}
        {filteredRecords && (
          <TablePagination
            rowsPerPageOptions={[20, 50]}
            component="div"
            count={totalRecordsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
      <CustomerDialog
        onClose={() => {
          setCustomerDialogIsOpen(false);
          setCustomer(null);
        }}
        open={customerDialogIsOpen}
        customer={customer}
      />
      <VVDialog
        onClose={() => setVVDialogOpenIsOpen(false)}
        open={VVDialogOpenIsOpen}
        htmlReport={VVReport}
        // vehicleIds={vehicleIds ? VV : null}
        // zipcode={zipcode}
      />
      <YMMDialog
        onClose={() => {
          setYMMDialogOpenIsOpen(false);
          setYMMM(null);
        }}
        open={YMMDialogOpenIsOpen}
        ymmm={ymmm}
      />
      <FICODialog
        onClose={() => setFICODialogOpenIsOpen(false)}
        open={FICODialogOpenIsOpen}
        ficoHtml={ficoHtml}
      />
      <ComplianceDialog
        onClose={() => setComplianceDialogOpenIsOpen(false)}
        open={complianceDialogOpenIsOpen}
        htmlReport={complianceReport}
      />
      <ConfirmationDialog
        open={showPrequalAck}
        title="Confirm"
        message="You understand by clicking the SUBMIT button, you are providing the company written instruction under the Fair Credit Reporting Act to obtain your personal credit information. You authorize this company to obtain such information solely to conduct a prequalification for credit. Submission will not affect your credit score"
        firstButtonLabel="Cancel"
        firstButtonFunction={() => {
          setPrequalAck(false);
        }}
        secondButtonLabel="Submit"
        secondButtonFunction={() => {
          callPrequal();
          setPrequalAck(false);
        }}
      />
      <InformationDialog
        open={showPrequalInputError}
        title="Info"
        message="Please update mandatory details of customer to run prequal"
        firstButtonLabel="OK"
        firstButtonFunction={() => {
          setPrequalInputError(false);
        }}
      />
      <InformationDialog
        open={showNotSubscribedError}
        title="Info"
        message="You are not subscripbed to this feature"
        firstButtonLabel="OK"
        firstButtonFunction={() => {
          setShowNotSubscribedError(false);
        }}
      />
    </div>
  );
};

// export default SLGrid;
export default isLoadingHOC(MainGrid);
