import React from "react";

const PageNotFound = () => {
  return (
    <div>
      <div id="info" style={{ textAlign: "center" }}>
        <h2>This page could not be found</h2>
      </div>
    </div>
  );
};

export default PageNotFound;
