import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import { MdCheckBox } from "react-icons/md";

import { useMutation, useQuery } from "@apollo/client";
import { GENERATE_TRADEPENDING_REPORT } from "../../../graphql/mutations/Mutations";

import {
  Card,
  DialogTitle,
  Button,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import TDangerButton from "../../../utils/UI_Elements/TDangerButton";

import { COLORS } from "../../../utils/colors";
import "./VVDialog.styles.css";

const VV = (props) => {
  const { onClose, open, htmlReport } = props;

  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        fullWidth
        maxWidth={"md"}
        scroll={"paper"}
      >
        <DialogTitle
          style={{
            // background: `linear-gradient( to right,#212a38, #283b54, #2c4d71, #2d5f90, #2973b0 )`,
            background: "#283b54",
            color: COLORS.white,
          }}
        >
          Vehicle Value
        </DialogTitle>
        <div style={{ padding: 20 }}>
          <iframe
            className="iFrame"
            srcDoc={htmlReport}
            title="VV"
            type="text/html"
            width="99%"
            height="640vh"
          />
        </div>

        <DialogActions style={{ backgroundColor: "#f2f2f2" }}>
          <div className="HBottomButton">
            <Button
              className="HDialogButton"
              style={{ color: COLORS.primary }}
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VV;
