import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import { DialogTitle, Button } from "@material-ui/core";

import TDangerButton from "../../../utils/UI_Elements/TDangerButton";
import { COLORS } from "../../../utils/colors";

export const ComplianceDialog = (props) => {
  const { onClose, open, htmlReport } = props;

  const newWindow = () => {
    var tab = window.open("about:blank", "_blank");
    tab.document.write(htmlReport);
    tab.document.close();
    return tab;
  };

  return (
    <div>
      {newWindow}
      <Dialog
        onClose={onClose}
        open={open}
        fullWidth
        maxWidth={"md"}
        scroll={"paper"}
      >
        <DialogTitle
          style={{
            // background: `linear-gradient( to right,#212a38, #283b54, #2c4d71, #2d5f90, #2973b0 )`,
            background: "#283b54",
            color: COLORS.white,
          }}
        >
          Compliance
        </DialogTitle>
        <div
          style={{
            padding: 20,
            flex: 1,
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="FicoIFrame" style={{ width: "76%" }}>
            <iframe
              srcDoc={htmlReport}
              sandbox
              title="FICO Details"
              type="text/html"
              width="100%"
              height="640vh"
              frameBorder="0"
            />
          </div>
        </div>
        <DialogActions style={{ backgroundColor: "#f2f2f2" }}>
          <div className="FHBottomButton">
            <Button
              className="FHDialogButton"
              style={{ color: COLORS.primary }}
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ComplianceDialog;
