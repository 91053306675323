import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import { DialogTitle, Button } from "@material-ui/core";

import TDangerButton from "../../../utils/UI_Elements/TDangerButton";
import "./FICODialog.styles.css";
import { COLORS } from "../../../utils/colors";

// import isLoadingHOC from "../../../hoc/isLoadingHOC";

export const FICODialog = (props) => {
  const { onClose, open, ficoHtml } = props;

  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        fullWidth
        maxWidth={"md"}
        scroll={"paper"}
      >
        <DialogTitle
          style={{
            // background: `linear-gradient( to right,#212a38, #283b54, #2c4d71, #2d5f90, #2973b0 )`,
            background: "#283b54",
            color: COLORS.white,
          }}
        >
          FICO Details
        </DialogTitle>
        <div
          style={{
            padding: 20,
            flex: 1,
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="FicoIFrame" style={{ width: "76%" }}>
            <iframe
              // className="FicoIFrame"
              srcDoc={ficoHtml}
              title="FICO Details"
              type="text/html"
              width="100%"
              height="640vh"
              frameBorder="0"

              // style={{ border:"none" }}
            ></iframe>
          </div>
        </div>
        <DialogActions style={{ backgroundColor: "#f2f2f2" }}>
          <div className="FHBottomButton">
            <Button
              className="FHDialogButton"
              style={{ color: COLORS.primary }}
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default FICODialog;
