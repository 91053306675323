import React from "react";
import Lottie from "react-lottie";

import animationData from "../../assests/lottie/login_animation.json";
import dealerlineLogo from "../../assests/images/dealerlineLogo.jpg";

const Logo = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
      <img
        src={dealerlineLogo}
        height={60}
        width={200}
        style={{
          padding: "20px",
          height: "10%",
        }}
        alt="Logo"
      />
      <Lottie options={defaultOptions} width={"80vh"} height={"80vh"} />
    </div>
  );
};

export default Logo;
