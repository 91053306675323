import { gql } from "@apollo/client";

export const ON_SAVE_CUSTOMER = gql`
  subscription OnSaveCustomer {
    onSaveCustomer {
      response{
          first_name
      }
    }
  }
`;

export const ON_SAVE_VEHICLE = gql`
  subscription OnSaveVehicle {
    onSaveVehicle {
     response{
      vin
     }
    }
  }
`;

export const ON_CUSTOMER_VEHICLE_SAVE = gql`
  subscription OnLinkCustomerVehicle {
    onLinkCustomerVehicle {
      response{
       user_id
     }
    }
  }
`;

export const ON_VEHICLE_SOLD_CHANGE = gql`
  subscription OnVehicleSoldChange {
    onVehicleSoldChange {
      vehicleSoldStatus
    }
  }
`;
export const ON_FB_GROSS_CHANGE = gql`
  subscription OnFBGrossChange {
    onFBGrossChange {
      fbGrossValue
    }
  }
`;
export const ON_PLATE_TO_VIN = gql`
  subscription OnPlateToVin {
    onPlateToVin {
      vin
    }
  }
`;
export const ON_PREQUAL_CALL = gql`
  subscription OnPrequalCall {
    onPrequalCall {
      ficoScore
    }
  }
`;
export const ON_VEHICLE_VALUATION = gql`
  subscription OnVehicleValuation {
    onVehicleValuation {
      vehicleValue
    }
  }
`;

export const ON_SAVE_CUSTOMER_SL = gql`
  subscription OnSaveCustomerSL {
    onSaveCustomerSL {
      response{ 
      user_id
    }
    }
  }
`;
export const ON_PARTIAL_SAVE_CUSTOMER_SL = gql`
  subscription OnPartialSaveCustomerSL {
    onPartialSaveCustomerSL {
       response{
      user_id
    }
    }
  }
`;
export const ON_SAVE_VEHICLE_SL = gql`
  subscription OnSaveVehicleSL {
    onSaveVehicleSL {
       response{
      vehicle_id
    }
    }
  }
`;
export const ON_COMPLETE_TRANSACTION = gql`
  subscription OnCompleteTransaction {
    onCompleteTransaction {
      response {
        documentId
      }
    }
  }
`;
export const ON_RECORD_DEALERSHIP_SALES = gql`
  subscription OnRecordDealershipSales {
    onRecordDealershipSales {
      id
    }
  }
`;

export const ON_INITIATE_PAYMENT = gql`
  subscription OnInitiatePayment {
    onInitiatePayment {
      id
    }
  }
`;
export const ON_PLATE_TO_VIN_SL = gql`
  subscription OnPlateToVinSL {
    onPlateToVinSL {
      vin
    }
  }
`;

export const ON_INSERT_VEHICLE = gql`
  subscription OnInsertVehicle {
    onInsertVehicle {
      vin
    }
  }
`;
export const ON_INSERT_CUSTOMER = gql`
  subscription OnInsertCustomer {
    onInsertCustomer {
      firstName
    }
  }
`;
