import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useMutation, useSubscription } from "@apollo/client";

import "./SalesLine.styles.css";
import {
  ON_FB_GROSS_CHANGE,
  ON_PLATE_TO_VIN,
  ON_PREQUAL_CALL,
  ON_VEHICLE_VALUATION,
  ON_PLATE_TO_VIN_SL,
} from "../../../graphql/subscriptions/Subscriptions";
import { TRIGGER_SALESLINE_KPI_STATS } from "../../../graphql/mutations/Mutations";

const INITIAL_DATA = {
  carsSold: "N/A",
  preQuals: "N/A",
  preQualsClosing: "N/A",
  vehVals: "N/A",
  vehValsClosing: "N/A",
  vinClosing: "N/A",
  vins: "N/A",
  sumFBGross: 0,
  avgFBGross: 0,
};
const SalesLine = (props) => {
  // console.log("sales line mini grid", props);

  //Declare the mutation to which subscription listen to
  const [triggerStats, { loading: loading, data }] = useMutation(
    TRIGGER_SALESLINE_KPI_STATS
  );

  //Define the subscription
  const { data: plateToVINExecuted, loading: plateToVinLoading } =
    useSubscription(ON_PLATE_TO_VIN);
  const { data: prequalCallExecuted, loading: prequalLoading } =
    useSubscription(ON_PREQUAL_CALL);
  const { data: vvExecuted, loading: vvLoading } =
    useSubscription(ON_VEHICLE_VALUATION);
  const { data: fbGrossChanged, loading: fbGrossLoading } =
    useSubscription(ON_FB_GROSS_CHANGE);
  const { data: plateDecodedSL, loading: onPlateLoading } =
    useSubscription(ON_PLATE_TO_VIN_SL);

  const [salesData, setSalesData] = useState(INITIAL_DATA);

  //Make the subscription listen to real time data of logged in user
  useEffect(() => {
    triggerStats({ variables: { userId: sessionStorage.getItem("user_id") } });
  }, [
    plateToVINExecuted,
    prequalCallExecuted,
    vvExecuted,
    fbGrossChanged,
    plateDecodedSL,
  ]);

  //Gets the real time data and sets in states which is displayed UI fields
  useEffect(() => {
    if (data) {
      // console.log("sales line details", data);
      let errors = data.errors;
      if (errors) {
        // console.log(errors[0].message);
      } else {
        if (data.getDealershipExternalIntegrationCallCounts) {
          // console.log(
          //   "inside",
          //   data.getDealershipExternalIntegrationCallCounts
          // );
          setSalesData(data.getDealershipExternalIntegrationCallCounts);
        }
      }
    }

    // console.log("after", salesData);
  }, [data]);

  return (
    <Paper elevation={1} variant="outlined" className="SalesLPaper">
      <Table className="SalesLTable">
        <TableHead>
          <TableRow className="SalesLTableHead">
            <TableCell>Vehicles Sold</TableCell>
            <TableCell></TableCell> <TableCell></TableCell>
            <TableCell># VINS</TableCell>
            <TableCell># Veh Vals</TableCell>
            <TableCell># Prequals</TableCell>
            <TableCell>Total/Avg Gross</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow style={{ height: 10 }}>
            <TableCell
              className="ItemBold"
              style={{
                border: "1px solid #283b54",
                margin: 0,
                padding: 0,
              }}
              rowspan={2}
              className="carsSold"
            >
              <p style={{ fontSize: "25px", margin: 0 }}>
                {salesData.carsSold}
              </p>
            </TableCell>
            <TableCell></TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>{salesData.vins}</TableCell>
            <TableCell>{salesData.vehVals}</TableCell>
            <TableCell>{salesData.preQuals}</TableCell>
            <TableCell>$ {salesData.sumFBGross}</TableCell>
          </TableRow>
          <TableRow
            style={{ height: 10, background: "rgba(0, 161, 255, 0.15)" }}
          >
            <TableCell></TableCell>
            <TableCell>Closing %</TableCell>
            <TableCell>{salesData.vinClosing}%</TableCell>
            <TableCell>{salesData.vehValsClosing}%</TableCell>
            <TableCell>{salesData.preQualsClosing}%</TableCell>
            <TableCell>$ {salesData.avgFBGross}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default SalesLine;
