import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";

import LogoComponent from "../../../components/auth/Logo.component";
import LoginComponent from "../../../components/auth/Login/Login.component";

import { LoginPageStyles } from "./Login.styles";

export const Login = (props) => {
  return (
    <>
      <Grid container style={LoginPageStyles.pageWrapper}>
        <Grid item xs={4} sm={4} />
        <Grid item xs={4} sm={4} style={LoginPageStyles.card}>
          <Paper elevation={0} style={LoginPageStyles.paper}>
            <LoginComponent handleLogin={props.handleLogin} />
          </Paper>
        </Grid>
        <Grid item xs={4} sm={4} />
        <div style={LoginPageStyles.footer}>
          <p style={{ marginLeft: "6%" }}>
            2022-Dealer Line,LLC. All rights reserved |{" "}
            <a style={{ cursor: "pointer" }}>Privacy Policy</a>
          </p>
        </div>
      </Grid>
    </>
  );
};

export default Login;
