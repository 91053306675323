import { COLORS } from "../../../utils/colors";
export const LoginStyles = {
  loginBox: {
    height: "100vh",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    backgroundColor: "#EFF9FF",
  },

  read: {
    marginTop: 16,
    marginBottom: 16,
  },

  loginButton: {
    // marginTop: 10,
    marginBottom: 24,
  },

  forgotButton: {
    alignSelf: "flex-end",
    color: "#304B77",
    cursor: "pointer",
    marginTop: 10,
    fontSize: 14,
  },
  inputField: {
    border: 3,
  },
  disclosure: {
    fontSize: 10,
    color:"#304B77",
  },
};
