export const LoginPageStyles = {
  lottieBackground: {
    backgroundImage:
      "linear-gradient(to right, #212a38, #283b54, #2c4d71, #2d5f90, #2973b0)",
  },
  card: {
    justifyContent: "center",
    display: "flex",
  },
  pageWrapper: {
    backgroundColor: "#EFF9FF",
  },
  paper: {
    backgroundColor: "#EFF9FF",
  },
  footer: {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
    color: "#304B77",
    textAlign: "left",
    borderTop: "1px solid #daf1ff",
    backgroundColor:"#e2f4ff",
    fontSize: 11,

  },
};
