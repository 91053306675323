import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import { COLORS } from "../colors";

const styles = {
  root: {
    color: COLORS.error,
    height: 40,
    "&:hover": {
      backgroundColor: "white",
    },
  },
};
const TDangerButton = (props) => {
  const { classes, children, className, ...other } = props;
  return (
    <Button className={clsx(classes.root, className)} {...other}>
      {children || "class names"}
    </Button>
  );
};

TDangerButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  TDangerButton: PropTypes.string,
};

export default withStyles(styles)(TDangerButton);
