import React, { useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import { useReactToPrint } from "react-to-print";
import { Card, DialogTitle, Button } from "@material-ui/core";

import PContainedButton from "../../../utils/UI_Elements/PContainedButton";
import TDangerButton from "../../../utils/UI_Elements/TDangerButton";
import "./CustomerDialog.styles.css";

import { phoneNumberFormat } from "../../../utils/utils";

import { COLORS } from "../../../utils/colors";

const CustomerDialog = (props) => {
  const { onClose, open, customer } = props;

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  // console.log("customer", customer);
  return (
    <div>
      {customer === null ? null : (
        <Dialog
          onClose={onClose}
          open={open}
          fullWidth
          maxWidth={"sm"}
          scroll={"paper"}
        >
          <DialogTitle
            style={{
              background: "#283b54",
              color: COLORS.white,
            }}
          >
            Customer Details
          </DialogTitle>
          <DialogContent className="DialogContent" ref={printRef}>
            <div
              style={{
                padding: 20,
                backgroundColor: "#fafafa",
                borderRadius: 10,
                marginTop: 4,
                marginBottom: 4,
                boxShadow:
                  "0 7px 30px -10px rgba(150,170,180,0.8),0 15px 40px rgba(166,173,201,0.2)",
              }}
            >
              <div
                style={{
                  width: "100%",
                  flexDirection: "column",
                  textAlign: "center",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <div className="customerRow">
                  <Typography className="customerObject">Name</Typography>
                  <Typography className="customerValue">
                    {customer.first_name !== ""
                      ? customer.first_name +
                        " " +
                        customer.middle_name +
                        " " +
                        customer.last_name
                      : "N/A"}
                  </Typography>
                </div>
                <div className="customerRow">
                  <Typography className="customerObject">
                    Mobile number
                  </Typography>
                  <Typography className="customerValue">
                    {customer.mobile !== "N/A"
                      ? phoneNumberFormat(customer.mobile)
                      : "N/A"}
                  </Typography>
                </div>
                <div className="customerRow">
                  <Typography className="customerObject">
                    Work number
                  </Typography>
                  <Typography className="customerValue">
                    {customer.work !== "N/A"
                      ? phoneNumberFormat(customer.work)
                      : "N/A"}
                  </Typography>
                </div>
                <div className="customerRow">
                  <Typography className="customerObject">Email</Typography>
                  <Typography className="customerValue">
                    {customer.email !== "" ? customer.email : "N/A"}
                  </Typography>
                </div>
                <div className="customerRow">
                  <Typography className="customerObject">
                    Unit or Apt number
                  </Typography>
                  <Typography className="customerValue">
                    {customer.apt_number !== "" ? customer.apt_number : "N/A"}
                  </Typography>
                </div>
                <div className="customerRow">
                  <Typography className="customerObject">Address</Typography>
                  <Typography className="customerValue">
                    {customer.street !== "" ? customer.street : "N/A"}
                  </Typography>
                </div>
                <div className="customerRow">
                  <Typography className="customerObject">City</Typography>
                  <Typography className="customerValue">
                    {customer.city !== "" ? customer.city : "N/A"}
                  </Typography>
                </div>
                <div className="customerRow">
                  <Typography className="customerObject">
                    State/Province/Region
                  </Typography>
                  <Typography className="customerValue">
                    {customer.state !== "" ? customer.state : "N/A"}
                  </Typography>
                </div>
                <div className="customerRow">
                  <Typography className="customerObject">Zipcode</Typography>
                  <Typography className="customerValue">
                    {customer.zipcode !== "" ? customer.zipcode : "N/A"}
                  </Typography>
                </div>
                <div className="customerRow">
                  <Typography className="customerObject">Stock Number</Typography>
                  <Typography className="customerValue">
                    {customer.stock_number !== "" ? customer.stock_number : "N/A"}
                  </Typography>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#f2f2f2" }}>
            <div className="HBottomButton">
              <Button
                className="HDialogButton"
                style={{ color: COLORS.primary, marginRight: 4 }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                className="HDialogButton"
                style={{ backgroundColor: COLORS.primary, color: COLORS.white }}
                onClick={handlePrint}
              >
                Print
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default CustomerDialog;
