const ERROR = {
  invalidEmail: "Invalid Email Address",
  invalidPassword:
    "Invalid Password",
  passwordNotMatching:
    "Current password and New Password does not match, please TRY again.",
  somethingWentWrong: "Something went Wrong! Please contact Salesline support",
};

export { ERROR };
