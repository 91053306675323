import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import EmailIcon from "@material-ui/icons/Email";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";

import { FORGOT_PASSWORD } from "../../../graphql/mutations/Mutations";

import { ERROR } from "../../../utils/error";
import { ERROR_TYPES } from "../../../utils/errorTypes";
import { validEmail } from "../../../utils/utils";
import { LABELS } from "../../../utils/constants/Labels";
import { CONSTANTS } from "../../../utils/constants/Constants";

import PContainedButton from "../../../utils/UI_Elements/PContainedButton";
import InfoDialog from "../../../utils/UI_Elements/InfoDialog/InfoDialog.component";

import isLoadingHOC from "../../../hoc/isLoadingHOC";

import { FPStyles } from "./ForgetPassword.styles.js";
import { COLORS } from "../../../utils/colors";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#304B77",
      borderRadius: 7,
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E15656",
      borderRadius: 7,
    },

    "& .MuiOutlinedInput-input": {
      color: "#304B77",
    },

    "& .MuiInputLabel-outlined": {
      color: "#304B77",
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#E15656",
    },

    "& .MuiIconButton-root": {
      color: "#B5E3FF",
    },
  },
});

const ForgetPassword = (props) => {
  const history = useHistory();
  const { setLoading } = props;
  const classes = useStyles();

  const [FP, setFP] = useState({
    email: CONSTANTS.EMPTY_STRING,
    emailError: CONSTANTS.EMPTY_STRING,
  });

  const [openInfoDialog, setInfoDialog] = useState(false);

  const [fetchForgotPassword, { loading, data }] = useMutation(FORGOT_PASSWORD);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (data) {
      let errors = data.forgotPassword.errors;
      if (errors) {
        console.log(errors);
        if (errors[0].message === ERROR_TYPES.INVALID_EMAIL) {
          console.log(errors[0].message);
          setFP({
            ...FP,
            emailError: errors[0].message,
          });
        }
      } else {
        console.log(data);
        openInfoDialogHandler();
      }
    }
  }, [data]);

  const closeInfoDialogHandler = () => {
    setInfoDialog(false);
  };

  const openInfoDialogHandler = () => {
    setInfoDialog(true);
  };

  const validate = (field, value) => {
    let error = CONSTANTS.EMPTY_STRING;
    if (field === "email") {
      if (!validEmail(value)) {
        error = ERROR.invalidEmail;
      }
      if (value === CONSTANTS.EMPTY_STRING) {
        error = CONSTANTS.EMPTY_STRING;
      }
      setFP({
        ...FP,
        email: value,
        emailError: error,
      });
    }
  };

  const loginHandler = () => {
    history.push("/");
  };

  const FPHandler = () => {
    if (!validEmail(FP.email)) {
      setFP({
        ...FP,
        emailError: ERROR.invalidEmail,
      });
    } else {
      fetchForgotPassword({ variables: { email: FP.email } });
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      mx={10}
      style={FPStyles.forgetBox}
    >
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        style={{ color: "#304B77" }}
      >
        Forgot Password?
      </Typography>

      <TextField
        fullWidth
        id="outlined-basic"
        label="Email Address"
        variant="outlined"
        margin="normal"
        value={FP.email}
        error={FP.emailError}
        helperText={FP.emailError}
        className={classes.root}
        onChange={(event) => {
          validate("email", event.target.value);
        }}
      />

      <PContainedButton onClick={FPHandler}>Reset Password</PContainedButton>

      <Typography
        style={FPStyles.backToLoginButton}
        onClick={loginHandler}
        gutterBottom
        align="left"
      >
        Go back to Login
      </Typography>

      <InfoDialog
        onClose={closeInfoDialogHandler}
        open={openInfoDialog}
        title={LABELS.CHECK_MAIL}
        body={LABELS.PASSWORD_RECOVER_INSTRUCTION}
        icon={EmailIcon}
      />
    </Box>
  );
};

export default isLoadingHOC(ForgetPassword);
