const minMaxLength = (text, minLength, maxLength) => {
  let result = !text || text.length < minLength;
  if (maxLength) result = result || text.length < minLength;
  return result;
};

const validEmail = (text) => {
  const regex = RegExp(/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+([A-Za-z]{2,})+$/);
  return regex.test(text);
};

// Minimum eight characters, at least one letter, one number and one special character
const validPassword = (text) => {
  const regex = RegExp(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
  );
  return regex.test(text);
};

const phoneNumberFormat = (number) => {
  const phoneNumber = number.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

export { minMaxLength, validEmail, validPassword,phoneNumberFormat };
