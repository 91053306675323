import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";

import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";

import PContainedButton from "../../../utils/UI_Elements/PContainedButton";
import TDangerButton from "../../../utils/UI_Elements/TDangerButton";
import SuccessIcon from "@material-ui/icons/CheckCircle";

import InfoDialog from "../../../utils/UI_Elements/InfoDialog/InfoDialog.component";

import { RESET_PASSWORD } from "../../../graphql/mutations/Mutations";

import { ERROR } from "../../../utils/error";
import { validPassword } from "../../../utils/utils";
import { CONSTANTS } from "../../../utils/constants/Constants";
import { LABELS } from "../../../utils/constants/Labels";

import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";

import { RPStyles } from "./ResetPassword.styles";

import isLoadingHOC from "../../../hoc/isLoadingHOC";

const ResetPassword = (props) => {
  const userId = props.userId;
  const history = useHistory();
  const { setLoading } = props;

  const [RP, setRP] = useState({
    newPassword: CONSTANTS.EMPTY_STRING,
    confirmPassword: CONSTANTS.EMPTY_STRING,
    newPasswordError: CONSTANTS.EMPTY_STRING,
    confirmPasswordError: CONSTANTS.EMPTY_STRING,
  });
  const [openInfoDialog, setInfoDialog] = useState(false);

  const [fetchResetPassword, { loading, data }] = useMutation(RESET_PASSWORD);

  const [newPasswordValues, setNewPasswordValues] = React.useState({
    newPassword: CONSTANTS.EMPTY_STRING,
    showNewPassword: false,
  });
  const [confirmPasswordValues, setConfirmPasswordValues] = React.useState({
    confirmPassword: CONSTANTS.EMPTY_STRING,
    showConfirmPassword: false,
  });

  const handleClickShowNewPassword = () => {
    setNewPasswordValues({
      ...newPasswordValues,
      showNewPassword: !newPasswordValues.showNewPassword,
    });
  };
  const handleClickShowConfirmPassword = () => {
    setConfirmPasswordValues({
      ...confirmPasswordValues,
      showConfirmPassword: !confirmPasswordValues.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleNewPasswordChange = (prop) => (event) => {
    setNewPasswordValues({ ...newPasswordValues, [prop]: event.target.value });
    validate("newPassword", event.target.value);
  };
  const handleConfirmPasswordChange = (prop) => (event) => {
    setConfirmPasswordValues({
      ...confirmPasswordValues,
      [prop]: event.target.value,
    });
    validate("confirmPassword", event.target.value);
  };

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (data) {
      let errors = data.resetPassword.errors;
      if (errors) {
      } else {
        openInfoDialogHandler();
      }
    }
  }, [data]);

  const resetHandler = () => {
    if (!validPassword(RP.newPassword)) {
      setRP({
        ...RP,
        newPasswordError: ERROR.invalidPassword,
      });
    } else if (!validPassword(RP.confirmPassword)) {
      setRP({
        ...RP,
        confirmPasswordError: ERROR.invalidPassword,
      });
    } else if (RP.newPassword !== RP.confirmPassword) {
      setRP({
        ...RP,
        confirmPasswordError: ERROR.passwordNotMatching,
      });
    } else {
      fetchResetPassword({
        variables: {
          resetPassword: { userId: userId, password: RP.newPassword },
        },
      });
    }
  };

  const validate = (field, value) => {
    let error = "";
    switch (field) {
      case "newPassword":
        if (!validPassword(value)) {
          error = ERROR.invalidPassword;
        }
        if (value === "") {
          error = "";
        }
        setRP({
          ...RP,
          newPassword: value,
          newPasswordError: error,
        });
        break;
      case "confirmPassword":
        if (!validPassword(value)) {
          error = ERROR.invalidPassword;
        }
        if (value === "") {
          error = "";
        }
        setRP({
          ...RP,
          confirmPassword: value,
          confirmPasswordError: error,
        });

        break;
      default:
        break;
    }
  };

  const goBackHandler = () => {
    history.push("/");
  };

  const closeInfoDialogHandler = () => {
    setInfoDialog(false);
    goBackHandler();
  };

  const openInfoDialogHandler = () => {
    setInfoDialog(true);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      mx={10}
      style={RPStyles.resetBox}
    >
      <Typography variant="h4" gutterBottom align="left">
        Reset Password
      </Typography>

      <TextField
        fullWidth
        id="outlined-basic"
        label="Create New Password"
        variant="outlined"
        size="small"
        margin="normal"
        type={newPasswordValues.showNewPassword ? "text" : "password"}
        value={newPasswordValues.newPassword}
        error={RP.newPasswordError}
        helperText={RP.newPasswordError}
        onChange={handleNewPasswordChange("newPassword")}
        InputProps={{
          style: { padding: 0 },
          endAdornment: (
            <InputAdornment style={{ paddingRight: 0 }} position="end">
              <IconButton
                onClick={handleClickShowNewPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {newPasswordValues.showNewPassword ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        fullWidth
        id="outlined-basic"
        label="Confirm Password"
        variant="outlined"
        size="small"
        margin="normal"
        type={confirmPasswordValues.showConfirmPassword ? "text" : "password"}
        value={confirmPasswordValues.confirmPassword}
        error={RP.confirmPasswordError}
        helperText={RP.confirmPasswordError}
        onChange={handleConfirmPasswordChange("confirmPassword")}
        InputProps={{
          style: { padding: 0 },
          endAdornment: (
            <InputAdornment style={{ paddingRight: 0 }} position="end">
              <IconButton
                onClick={handleClickShowConfirmPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {confirmPasswordValues.showConfirmPassword ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <PContainedButton style={RPStyles.resetButton} onClick={resetHandler}>
        Save Password
      </PContainedButton>

      <Typography
        style={RPStyles.goBack}
        onClick={goBackHandler}
        gutterBottom
        align="left"
      >
        Go Back to Login
      </Typography>

      <InfoDialog
        onClose={closeInfoDialogHandler}
        open={openInfoDialog}
        title={LABELS.PASSWORD_UPDATED}
        body={LABELS.LOGIN_AGAIN}
        icon={SuccessIcon}
      />
    </Box>
  );
};

export default isLoadingHOC(ResetPassword);
