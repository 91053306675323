import React, { useEffect, useState } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useMutation, useSubscription } from "@apollo/client";

import "./ServiceLine.styles.css";
import {
  ON_INITIATE_PAYMENT,
  ON_COMPLETE_TRANSACTION,
} from "../../../graphql/subscriptions/Subscriptions";
import { TRIGGER_SERVICELINE_KPI_STATS } from "../../../graphql/mutations/Mutations";
const ServiceLine = (props) => {
  //Declare the mutation to which subscription listen to
  const [triggerStats, { loading1, data }] = useMutation(
    TRIGGER_SERVICELINE_KPI_STATS
  );

  //Define the subscription
  // const { data1, loading } = useSubscription(FETCH_SERVICELINE_KPI_STATS);

  const [products, setProducts] = useState(null);
  const [soldCount, setSoldCount] = useState(0);
  const [totalProductsSold, setTotalProductsSold] = useState(0);
  const [totalGross, setTotalGross] = useState(0);
  const [totalAvgGross, setTotalAvgGross] = useState(0);

  const { data: paymentInitiated, loading: initiatePaymentLoading } =
    useSubscription(ON_INITIATE_PAYMENT);
  const { data: transactionCompleted, loading: transactionLoading } =
    useSubscription(ON_COMPLETE_TRANSACTION);

  useEffect(() => {
    triggerStats({ variables: { userId: sessionStorage.getItem("user_id") } });
  }, [paymentInitiated, transactionCompleted]);

  useEffect(() => {
    if (data) {
      let errors = data.errors;
      if (errors) {
      } else {
        if (data.getDealershipProductsWithSoldCount) {
          setProducts(data.getDealershipProductsWithSoldCount.details);
          setSoldCount(data.getDealershipProductsWithSoldCount.carsSold);
          let totalCount = 0;
          let totalGross = 0;
          let totalAvgGross = 0;
          for (const pro of data.getDealershipProductsWithSoldCount.details) {
            totalCount = totalCount + pro.count;
            totalGross = totalGross + pro.gross;
          }
          if (totalCount !== 0) totalAvgGross = totalGross / totalCount;
          else totalAvgGross = totalGross + 0;
          setTotalProductsSold(totalCount);
          setTotalGross(totalGross);
          setTotalAvgGross(totalAvgGross.toFixed(2));
          // console.log("end", totalProductsSold, totalGross, totalAvgGross);
        }
      }
    }
    // if (data1) {
    //   console.log("sub data", data1);
    // }
    // console.log("after", products);
  }, [data]);

  return (
    <Paper elevation={1} variant="outlined" className="ServiceLPaper">
      <Table className="ServiceLTable">
        <TableHead>
          <TableRow className="ServiceLTableHead">
            <TableCell> # Menus </TableCell>
            <TableCell>Product Offered</TableCell>
            <TableCell># Product Sold</TableCell>
            <TableCell>Gross</TableCell>
            <TableCell>Ave Gross</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products != null &&
            products.map((product, index) => (
              <TableRow
                border="1"
                style={
                  index % 2
                    ? { background: "rgba(0, 161, 255, 0.15)" }
                    : { background: "white" }
                }
              >
                {index === 0 && (
                  <TableCell
                    className="ItemBold"
                    style={{ borderRight: "1px solid #283b54" }}
                    rowspan={products.length + 1}
                  >
                    <p style={{ fontSize: "30px" }}>{soldCount}</p>
                  </TableCell>
                )}
                <TableCell className="Start">{product.product_title}</TableCell>
                <TableCell>{product.count}</TableCell>
                <TableCell>${product.gross}</TableCell>
                <TableCell>
                  $
                  {product.gross !== 0
                    ? (product.gross / product.count).toFixed(2)
                    : 0}
                </TableCell>
              </TableRow>
            ))}
          {products != null && products.length > 0 ? (
            <TableRow>
              <TableCell className="ItemStartBold">Total</TableCell>
              <TableCell className="ItemBold">{totalProductsSold}</TableCell>
              <TableCell className="ItemBold">${totalGross}</TableCell>
              <TableCell className="ItemBold">${totalAvgGross}</TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell className="ItemStartBold" colspan="5">
                <p style={{ textAlign: "center" }}>
                  No products available in this Dealership{" "}
                </p>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default ServiceLine;
