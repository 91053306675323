import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  Redirect,
} from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  ApolloLink,
  from,
  split,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import ProtectedRoute from "./components/auth/ProtectedRoute.component";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

import Login from "./containers/pages/Login/Login.page";
import ForgotPassword from "./containers/pages/ForgotPassword/ForgotPassword.page";
import ResetPassword from "./containers/pages/ResetPassword/ResetPassword.page";
import Home from "./containers/pages/Home/Home.page";

import PageNotFound from "./utils/pageNotFound";

import Theme from "./theme/Theme";

console.log("env var", process.env.REACT_APP_GRAPHQL_URL);

if (!sessionStorage.getItem("logged")) {
  sessionStorage.setItem("logged", false);
}

const jwtToken = sessionStorage.getItem("jwt_token");
console.log("jwtt", jwtToken);
const API_URL =
  "https://sv26tiwdtja4pkwbdm32w6dgwm.appsync-api.us-east-2.amazonaws.com/graphql";
const API_KEY = "da2-lxg2mxiadzfkdorlcq6bcdnu4a";
const WSS_URL = API_URL.replace("https", "wss").replace(
  "appsync-api",
  "appsync-realtime-api"
);
const HOST = API_URL.replace("https://", "").replace("/graphql", "");
const api_header = {
  host: HOST,
  "x-api-key": API_KEY,
};
const header_encode = (obj) => btoa(JSON.stringify(obj));
const connection_url =
  WSS_URL +
  "?header=" +
  header_encode(api_header) +
  "&payload=" +
  header_encode({});

const { SubscriptionClient } = require("subscriptions-transport-ws");
const uuid4 = require("uuid").v4;

class UUIDOperationIdSubscriptionClient extends SubscriptionClient {
  generateOperationId() {
    // AppSync recommends using UUIDs for Subscription IDs but SubscriptionClient uses an incrementing number
    return uuid4();
  }
  processReceivedData(receivedData) {
    try {
      const parsedMessage = JSON.parse(receivedData);
      if (parsedMessage?.type === "start_ack") return; // sent by AppSync but meaningless to us
    } catch (e) {
      throw new Error("Message must be JSON-parsable. Got: " + receivedData);
    }
    super.processReceivedData(receivedData);
  }
}

const graphqlPrinter = require("graphql/language/printer");
const createAppSyncGraphQLOperationAdapter = () => ({
  applyMiddleware: async (options, next) => {
    // AppSync expects GraphQL operation to be defined as a JSON-encoded object in a "data" property
    options.data = JSON.stringify({
      query:
        typeof options.query === "string"
          ? options.query
          : graphqlPrinter.print(options.query),
      variables: options.variables,
    });

    // AppSync only permits authorized operations
    options.extensions = { authorization: api_header };

    // AppSync does not care about these properties
    delete options.operationName;
    delete options.variables;
    // Not deleting "query" property as SubscriptionClient validation requires it

    next();
  },
});

const errorLink = onError(({ graphqlErrors, networkError }) => {
  if (graphqlErrors) {
    graphqlErrors.map(({ message, location, path }) => {
      alert(`Graphql error ${message}`);
    });
  }
});

const httpLink = from([
  errorLink,
  new HttpLink({
    uri: API_URL,
  }),
]);

const wsLink = new WebSocketLink(
  new UUIDOperationIdSubscriptionClient(
    connection_url,
    {
      timeout: 5 * 60 * 1000,
      reconnect: true,
      lazy: true,
      connectionCallback: (err) =>
        console.log("connectionCallback", err ? "ERR" : "OK", err || ""),
    },
    WebSocket
  ).use([createAppSyncGraphQLOperationAdapter()])
);

const authLink = setContext((_, { headers }) => {
  return {
    data: "{}",
    method: "POST",
    headers: {
      ...headers,
      accept: "application/json, text/javascript",
      "content-encoding": "amz-1.0",
      "content-type": "application/json; charset=UTF-8",
      "x-api-key": API_KEY,
      authorisation: jwtToken
        ? jwtToken
        : sessionStorage.getItem("jwt_token")
        ? sessionStorage.getItem("jwt_token")
        : "",
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(ApolloLink.from([splitLink])),
});

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    sessionStorage.getItem("logged")
  );

  const handleLogin = () => {
    setIsLoggedIn(true);
    sessionStorage.setItem("logged", true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    sessionStorage.setItem("logged", false);
  };
  const getMuiTheme = () =>
    createTheme({
      typography: {
        fontFamily: `"Helvetica"`,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
      },
    });
  return (
    <div>
      {/* <MuiThemeProvider theme={getMuiTheme}> */}
      <ApolloProvider client={client}>
        <Theme>
          <Router>
            <Switch>
              <Route
                exact
                path="/"
                render={(props) =>
                  sessionStorage.getItem("user_id") !== null ? (
                    <Redirect to="/home" />
                  ) : (
                    <Login {...props} handleLogin={handleLogin} />
                  )
                }
              />
              <Route exact path="/forgotpassword">
                <ForgotPassword />
              </Route>
              <Route exact path="/resetpassword/:id">
                <ResetPassword />
              </Route>
              <ProtectedRoute
                exact
                path="/home"
                isLoggedIn={isLoggedIn}
                handleLogout={handleLogout}
                component={withRouter(Home)}
              />
              <Route component={PageNotFound} />
            </Switch>
          </Router>
        </Theme>
      </ApolloProvider>
      {/* </MuiThemeProvider> */}
    </div>
  );
};

export default App;
