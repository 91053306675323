import { COLORS } from "../../../utils/colors";

export const ForgotPasswordStyles = {
  lottieBackground: {
    backgroundColor: COLORS.backgroundColor,
  },
  contentBox: {
    backgroundColor: COLORS.backgroundColor,
  },
};
