import react, { useState } from "react";
// import Lottie from "lottie-react";
import Lottie from "react-lottie";
import * as carLoadingAnimation from "./carLoadingAnimation.json";

export const isLoadingHOC = (WrappedComponent) => {
    const HOC = (props) => {
        const [isLoading, setLoading] = useState(true);

        const setLoadingState = (isComponentLoading) => {
            setLoading(isComponentLoading);
        };

        return (
            <>
                {isLoading && (
                    <div
                        style={{
                            // background: "rgba(0,0,0,0.4)",
                            width: "100vw",
                            height: "100vh",
                            position: "fixed",
                            zIndex: 99,
                            justifyItems: "center",
                            top: 0,
                        }}
                    >
                        <Lottie
                            options={{
                                animationData: carLoadingAnimation.default,
                                loop: true,
                                autoplay: true,
                                rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice",
                                },
                            }}
                            style={{
                                position: "fixed",
                                width: 400,
                                height: 400,
                                top: "50%",
                                left: "50%",
                                marginTop: -200,
                                marginLeft: -200,
                            }}
                        />
                    </div>
                )}
                <WrappedComponent {...props} setLoading={setLoadingState} />
            </>
        );
    };
    return HOC;
};
export default isLoadingHOC;
