import React, { useState, useEffect } from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Grid from "@material-ui/core/Grid";
import moment from "moment-timezone";
import InputLabel from "@mui/material/InputLabel";
import { useMutation, useQuery } from "@apollo/client";
import { useSubscription } from "@apollo/react-hooks";

import SearchAndFilter from "./SearchAndFilter/SearchAndFilter.component";
import CustomerDialog from "./CustomerDialog/CustomerDialog.component";
import VVDialog from "./VVDialog/VVDialog.component";
import YMMDialog from "./YMMDialog/YMMDialog.component";
import FICODialog from "./FICODialog/FICODialog.component";

import ConfirmationDialog from "../../utils/UI_Elements/Confirmation_Dialog/ConfirmationDialog.Component";
import InformationDialog from "../../utils/UI_Elements/Information_Dialog/InformationDialog.Component";

import { GET_VEHICLE_IDS } from "../../graphql/mutations/Mutations";
import { TRIGGER_SALESLINE_MAIN_GRID } from "../../graphql/mutations/Mutations";
import {
  RECALL_PREQUAL,
  CALL_PREQUAL,
} from "../../graphql/mutations/Mutations";
import { UPDATE_VEHICLE_SOLD } from "../../graphql/mutations/Mutations";
import { UPDATE_FBGROSS } from "../../graphql/mutations/Mutations";
import {
  ON_SAVE_CUSTOMER,
  ON_SAVE_VEHICLE,
  ON_PREQUAL_CALL,
  ON_VEHICLE_VALUATION,
  ON_VEHICLE_SOLD_CHANGE,
  ON_SAVE_CUSTOMER_SL,
  ON_SAVE_VEHICLE_SL,
  ON_PARTIAL_SAVE_CUSTOMER_SL,
  ON_COMPLETE_TRANSACTION,
  ON_RECORD_DEALERSHIP_SALES,
} from "../../graphql/subscriptions/Subscriptions";
import { GENERATE_TRADEPENDING_REPORT } from "../../graphql/mutations/Mutations";

import "./SLGrid.styles.css";
import { COLORS } from "../../utils/colors";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { TableSortLabel } from "@material-ui/core";

import isLoadingHOC from "../../hoc/isLoadingHOC";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: 80,
  },

  menu: {
    width: 100,
  },
}));

const PREQUAL_CUSTOMER = {
  _user_id: "",
  _first_name: "",
  _middle_name: "",
  _last_name: "",
  _street: "",
  _city: "",
  _state: "",
  _zipcode: "",
};

const SLGrid = (props) => {
  const { setLoading } = props;
  const classes = useStyles();

  const [customerDialogIsOpen, setCustomerDialogIsOpen] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [VVDialogOpenIsOpen, setVVDialogOpenIsOpen] = useState(false);
  const [VV, setVV] = useState(null);
  const [zipcode, setZipcode] = useState("");
  const [YMMDialogOpenIsOpen, setYMMDialogOpenIsOpen] = useState(false);
  const [ymmm, setYMMM] = useState(null);
  const [FICODialogOpenIsOpen, setFICODialogOpenIsOpen] = useState(false);
  const [ficoHtml, setFicoHtml] = useState(null);
  const [search, setSearch] = useState("");
  const [rangeData, setRangeData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [vehicleSoldValue, setVehicleSoldValue] = useState("");
  const [fbGrossInputValue, setFbGrossInputValue] = useState(1);
  const [VVReport, setVVReport] = useState("");
  const [end, setEnd] = useState(false);
  const [serverPage, setServerPage] = useState(1);
  const [page, setPage] = useState(0);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isFBGrossChanged, setIsFBGrossChanged] = useState(false);
  const [customerId, setCustomerId] = useState(false);
  const [prequalCustomer, setPrequalCustomer] = useState(PREQUAL_CUSTOMER);
  const [showPrequalAck, setPrequalAck] = useState(false);
  const [showPrequalInputError, setPrequalInputError] = useState(false);

  //let isFBGrossChanged = false;
  let fbGross = 0;

  //We have implemented pagination concept in real time, pagination is based
  //on next and previous button. The data should be real time and also we have
  //limit of API payload size, so the real time data listen to changes on the
  //current page the user is in, For example, if user is page 10, and edits the
  //vehicle sold in the page 10 and we need to show the change of value in grid
  //realtime

  //If new record is added, if we displayed on page 1

  /**********************************************************************
   * The reason for using multiple useEffects in the file is that we need
   * to execute a set of statements only on change of specific state value
   * instead of listening to everything everytime as we have mutiple
   * integeration and real time, eact of the action to execute only on
   * modification of specific data
   ***********************************************************************/

  //Handler to move forward and backward between pages and load more data

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const today = moment(new Date()).format("MM/DD/YYYY");

  const [fetchVehicleIds, { loading: getVehicleIdsLoading, data: vehicleIds }] =
    useMutation(GET_VEHICLE_IDS);
  const [recallPrequal, { loading: recallLoading, data: recallResponse }] =
    useMutation(RECALL_PREQUAL);
  const [fetchReport, { loading: loadingReport, data: htmlReport }] =
    useMutation(GENERATE_TRADEPENDING_REPORT);
  const {
    loading: loadingMainGrid,
    error: MainGridError,
    data,
    refetch,
  } = useQuery(TRIGGER_SALESLINE_MAIN_GRID, {
    fetchPolicy: "cache-and-network",

    variables: {
      dealerCustomerInput: {
        agentUserId: sessionStorage.getItem("user_id"),
        pageCount: page,
        rowsPerPage: rowsPerPage,
        searchText: "",
        startDate:
          startDate === null
            ? null
            : moment(new Date(startDate)).format("YYYY-MM-DD"),
        endDate:
          endDate === null
            ? null
            : moment(new Date(endDate)).format("YYYY-MM-DD"),
      },
    },
  });
  //Define the subscription that captures real time data of logged in user
  const {
    data: onSaveCustomer,
    loading: customerLoading,
    error: onSaveCustomerError,
  } = useSubscription(ON_SAVE_CUSTOMER);
  const {
    data: onSaveVehicle,
    loading: vehicleLoading,
    error: onSaveVehicleError,
  } = useSubscription(ON_SAVE_VEHICLE);
  const {
    data: onPrequalCall,
    loading: onPrequalLoading,
    error: onPrequalCallError,
  } = useSubscription(ON_PREQUAL_CALL);
  const {
    data: onVehicleValuation,
    loading: vehicleValuationLoading,
    error: onVehicleValuationError,
  } = useSubscription(ON_VEHICLE_VALUATION);
  const {
    data: onVehicleSoldChange,
    loading: onvehicleSoldLoading,
    error: onVehicleSoldError,
  } = useSubscription(ON_VEHICLE_SOLD_CHANGE);
  const {
    data: onSaveCustomerSL,
    loading: onSaveCustomerSlLoading,
    error: onSaveCustomerSLError,
  } = useSubscription(ON_SAVE_CUSTOMER_SL);
  const {
    data: onPartialSaveCustomer,
    loading: onPartialSaveCustomerLoading,
    error: onPartialSaveCustomerError,
  } = useSubscription(ON_PARTIAL_SAVE_CUSTOMER_SL);
  const {
    data: onSaveVehicleSL,
    loading: onSaveVehicleSLLoading,
    error: onSaveVehicleSLError,
  } = useSubscription(ON_SAVE_VEHICLE_SL);
  const {
    data: onCompleteTransaction,
    loading: onCompleteTransactionLoading,
    error: onCompleteTransactionError,
  } = useSubscription(ON_COMPLETE_TRANSACTION);
  const {
    data: onRecordDealershipSales,
    loading: onRecordDealershipSalesLoading,
    error: onRecordDealershipSalesError,
  } = useSubscription(ON_RECORD_DEALERSHIP_SALES);

  const [salesData, setSalesData] = useState(null);

  const [
    updateVehicleSold,
    { loading: vehicleSoldLoading, data: vehicleSoldResponse },
  ] = useMutation(UPDATE_VEHICLE_SOLD);

  const [updateFBGross, { loading: fbGrossLoading, data: fbGrossResponse }] =
    useMutation(UPDATE_FBGROSS);

  const [initiatePrequal, { loading: prequalLoading, data: prequalResponse }] =
    useMutation(CALL_PREQUAL);

  useEffect(() => {
    refetch();
  }, [
    onSaveCustomer,
    onSaveVehicle,
    onPrequalCall,
    onVehicleValuation,
    onVehicleSoldChange,
    onSaveCustomerSL,
    onPartialSaveCustomer,
    onSaveVehicle,
    onCompleteTransaction,
    onRecordDealershipSales,
    page,
    rowsPerPage,
  ]);

  useEffect(() => {
    if (data) {
      let errors = data.errors;
      if (errors) {
        console.log("Error: ", data.errors);
      } else {
        setTotalRecordsCount(data.getDealerCustomer.totalRecordsCount);
        if (startDate !== null || endDate !== null || search !== "") {
          filterData(search, startDate, endDate, tableData);
        } else {
          setRangeData(data.getDealerCustomer.details);
          setTableData(data.getDealerCustomer.details);
        }
      }
    }
  }, [data, vehicleSoldResponse]);

  const filterData = (search, startDate, endDate, tableData) => {
    const sDate =
      startDate !== null ? moment(startDate).format("MM/DD/YYYY") : null;
    const eDate =
      endDate !== null ? moment(endDate).format("MM/DD/YYYY") : null;
    if (tableData) {
      setRangeData(
        tableData.filter((row) => {
          // search is not empty
          if (search !== "") {
            // search has start date and same as today
            if (moment(today).isSame(sDate)) {
              return (
                row.customer.last_name
                  .toUpperCase()
                  .startsWith(search.toUpperCase()) &&
                moment(
                  moment(row.date, "YYYY-MM-DDThh:mm:ss.000Z").format(
                    "MM/DD/YYYY"
                  )
                ).isSame(sDate)
              );
            } else if (sDate && eDate) {
              // search has between start and end date
              return (
                row.customer.last_name
                  .toUpperCase()
                  .startsWith(search.toUpperCase()) &&
                moment(
                  moment(row.date, "YYYY-MM-DDThh:mm:ss.000Z").format(
                    "MM/DD/YYYY"
                  )
                ).isBetween(sDate, eDate, undefined, "[]")
              );
            } else {
              return row.customer.last_name
                .toUpperCase()
                .startsWith(search.toUpperCase());
            }
          } else if (sDate || eDate) {
            // search is empty
            // start date same as today
            if (moment(today).isSame(sDate)) {
              return moment(
                moment(row.date, "YYYY-MM-DDThh:mm:ss.000Z").format(
                  "MM/DD/YYYY"
                )
              ).isSame(sDate);
            } else {
              // between start and end date
              return moment(
                moment(row.date, "YYYY-MM-DDThh:mm:ss.000Z").format(
                  "MM/DD/YYYY"
                )
              ).isBetween(sDate, eDate, undefined, "[]");
            }
          } else {
            return row;
          }
        })
      );
    }
  };

  React.useEffect(() => {
    if (tableData) {
      filterData(search, startDate, endDate, tableData);
    }
  }, [search, startDate, endDate, tableData]);

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MuiSelect: { outlined: { width: "70px", textAlign: "start" } },
        MuiOutlinedInput: { input: { width: "110px", height: "30px" } },
        MuiInputBase: {
          root: {
            height: "40px",
          },
          formControl: {
            height: "40px",
          },
        },
        MuiOutlinedInput: {
          root: {
            height: "40px",
          },
        },
        MUIDataTable: {
          responsiveScrollMaxHeight: { border: "1px solid black" },
        },
        MUIDataTableToolbar: {
          root: {
            display: "none",
          },
        },
        MuiTableHead: {
          root: {
            background:
              "linear-gradient( to right, #212a38, #283b54, #2c4d71, #2d5f90, #2973b0 ) !important",
          },
        },
        MuiTableCell: {
          root: {
            padding: "0px",
          },
          head: {
            color: "white",
            textAlign: "center",
            position: "fixed",
          },
          body: {
            textAlign: "center",
          },
        },
        MuiTableRow: {
          root: {
            "&$hover:hover": {
              backgroundColor: "none",
            },
          },
        },
        MuiButton: {
          root: {
            color: "white",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(even)": {
              background: "#0014ff1c",
            },
            "&:nth-child(odd)": {
              background: "white",
            },
          },
        },
        MUIDataTableHeadCell: {
          fixedHeader: {
            backgroundColor:
              "linear-gradient( to right, #212a38, #283b54, #2c4d71, #2d5f90, #2973b0 ) !important",
          },
          sortActive: {
            color: "white",
          },
          toolButton: {
            justifyContent: "center",
          },
          contentWrapper: {
            justifyContent: "center",
          },
        },
        MuiTableSortLabel: {
          icon: {
            color: "white !important",
          },
          "&$hover:hover": {
            color: "white !important",
          },
        },
      },
    });

  const getVehicleIds = (vin) => {
    if (vin.length === 17) {
      fetchVehicleIds({
        variables: {
          tradePendingInput: {
            vin: vin,
          },
        },
      });
    }
  };

  const recallPrequalHandler = async (customerId) => {
    await recallPrequal({
      variables: {
        recallPrequalInput: {
          customerId: customerId,
        },
      },
    });
  };

  const tradePendingReport = async (vehicleId) => {
    await fetchReport({
      variables: {
        tradePendingInput: {
          vin: vehicleId,
          zipCode: zipcode,
        },
      },
    });
  };

  React.useEffect(() => {
    if (vehicleIds) {
      tradePendingReport(vehicleIds.getVehicleId.vehicleIds[0].id);
    }
  }, [vehicleIds]);

  React.useEffect(() => {
    if (htmlReport) {
      setVVReport(htmlReport.generateTradePendingReport.report);
      setVVDialogOpenIsOpen(true);
    }
  }, [htmlReport]);

  React.useEffect(() => {
    if (recallResponse) {
      setFicoHtml(recallResponse.recallPrequal.htmlResponse);
      setFICODialogOpenIsOpen(true);
    }
  }, [recallResponse]);

  const callPrequal = async () => {
    await initiatePrequal({
      variables: {
        preQualInput: {
          userId: sessionStorage.getItem("user_id"),
          customerId: prequalCustomer._user_id,
          firstName: prequalCustomer._first_name,
          middleName:
            prequalCustomer._middle_name === "N/A"
              ? ""
              : prequalCustomer._middle_name,
          lastName: prequalCustomer._last_name,
          streetAddress: prequalCustomer._street,
          city: prequalCustomer._city,
          state: prequalCustomer._state,
          zipcode: prequalCustomer._zipcode,
        },
      },
    });
  };

  useEffect(() => {
    if (prequalResponse) {
      // console.error("pre", prequalResponse);
    }
  }, [prequalResponse]);
  const updateFBGrossHandler = (customerId, value) => {
    updateFBGross({
      variables: {
        fbGrossInput: {
          customerId: customerId,
          fbGrossValue: value,
        },
      },
    });
  };
  /*============== API call Loading ====================*/

  React.useEffect(() => {
    if (vehicleSoldLoading) setLoading(vehicleSoldLoading);
    // console.log("effect", vehicleSoldLoading, vehicleSoldResponse);
  }, [vehicleSoldLoading, vehicleSoldResponse]);

  React.useEffect(() => {
    setLoading(fbGrossLoading);
  }, [fbGrossLoading]);

  React.useEffect(() => {
    setLoading(recallLoading);
  }, [recallLoading]);

  React.useEffect(() => {
    setLoading(loadingReport);
  }, [loadingReport]);

  React.useEffect(() => {
    setLoading(loadingMainGrid);
  }, [loadingMainGrid]);

  /*=====================================================*/
  return (
    <div>
      <Paper elevation={1} variant="outlined" className="SLGridPaper">
        <Grid container alignItems="flex-end" className="SLGrid">
          <Grid item sm="6">
            {/* <Button variant="outlined" onClick={() => refetch()}>
              Refetch
            </Button> */}
          </Grid>
          <Grid item sm="6">
            <SearchAndFilter
              className="SearchAndFilter"
              search={search}
              setSearch={setSearch}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          </Grid>
        </Grid>
        {rangeData === null ? (
          <p>Loading</p>
        ) : (
          <div style={{ height: 540 }}>
            <div
              style={{
                overflowY: "scroll",
                height: 500,
                border: "1px solid #283b54",
              }}
              className="hideScrollbar"
            >
              <Table
                stickyHeader
                style={{
                  border: "none",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ background: "#283B54" }}>
                      Date In
                    </TableCell>
                    <TableCell style={{ background: "#283B54", width: "8%" }}>
                      Employee
                    </TableCell>
                    <TableCell style={{ background: "#283B54", width: "8%" }}>
                      Customer
                    </TableCell>
                    <TableCell style={{ background: "#283B54", width: "10%" }}>
                      YMM
                    </TableCell>
                    <TableCell style={{ background: "#283B54" }}>VV</TableCell>
                    <TableCell style={{ background: "#283B54" }}>
                      Payoff
                    </TableCell>
                    <TableCell style={{ background: "#283B54" }}>
                      Equity
                    </TableCell>
                    <TableCell style={{ background: "#283B54", width: "8%" }}>
                      FICO
                    </TableCell>
                    <TableCell style={{ background: "#283B54" }}>
                      Compliance
                    </TableCell>
                    <TableCell style={{ background: "#283B54" }}>
                      Offer After
                    </TableCell>
                    <TableCell style={{ background: "#283B54" }}>
                      Sold After
                    </TableCell>
                    <TableCell style={{ background: "#283B54" }}>
                      AftMkt Gross
                    </TableCell>
                    <TableCell style={{ width: "8%", background: "#283B54" }}>
                      Vehicle Sold
                    </TableCell>
                    <TableCell style={{ background: "#283B54", maxWidth: 100 }}>
                      F&B Gross
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rangeData.map((item, index) => (
                    <TableRow
                      key={item.customer.userId}
                      style={
                        index % 2
                          ? { background: "rgba(0, 161, 255, 0.2)" }
                          : { background: "white" }
                      }
                    >
                      <TableCell
                        style={{
                          cursor: "default",
                          paddingTop: 0,
                          paddingBottom: 0,
                          width: "8%",
                        }}
                      >
                        {moment
                          .utc(item.date, "YYYY-MM-DDThh:mm:ss")
                          .tz("America/Detroit")
                          .format("MM/DD/YY hh:mm a")}
                      </TableCell>
                      <TableCell
                        style={{
                          cursor: "default",
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        {item.serviceAgentName[0].toUpperCase() +
                          item.serviceAgentName.slice(1)}
                      </TableCell>
                      <TableCell
                        className="hyperlinkColor"
                        onClick={() => {
                          setCustomerDialogIsOpen(true);
                          setCustomer(item.customer);
                        }}
                        style={{
                          cursor: "pointer",
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        <u>
                          {item.customer.last_name !== null
                            ? item.customer.last_name[0].toUpperCase() +
                              item.customer.last_name.slice(1)
                            : "N/A"}
                        </u>
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          if (item.vehicle.vin !== "N/A") {
                            setYMMDialogOpenIsOpen(true);
                            setYMMM(item.vehicle);
                          }
                        }}
                        style={{
                          cursor: "pointer",
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        {item.vehicle.vin !== "N/A" ? (
                          <u className="hyperlinkColor">
                            {item.vehicle.make_year +
                              " " +
                              item.vehicle.make +
                              " " +
                              item.vehicle.model}
                          </u>
                        ) : (
                          "N/A"
                        )}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          if (item.vehicleValue) {
                            getVehicleIds(item.vehicle.vin);
                            setZipcode(item.customer.zipcode);
                          }
                        }}
                        style={{
                          cursor: "pointer",
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        {item.vehicleValue !== "N/A" ? (
                          <u className="hyperlinkColor">
                            {"$ " +
                              parseInt(item.vehicleValue, 10).toLocaleString(
                                "en-US"
                              )}
                          </u>
                        ) : (
                          "N/A"
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          cursor: "default",
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        {item.payoff !== "N/A"
                          ? "$ " +
                            parseInt(item.payoff, 10).toLocaleString("en-US")
                          : "N/A"}
                      </TableCell>
                      <TableCell
                        style={{
                          cursor: "default",
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        {item.equity !== "N/A"
                          ? "$ " +
                            parseInt(item.equity, 10)
                              .toLocaleString("en-US")
                              .replace("-", "") +
                            " "
                          : "N/A"}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          if (item.fico !== "N/A") {
                            recallPrequalHandler(item.customer.user_id);
                          }
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {item.FICO !== "N/A" ? (
                          <u className="hyperlinkColor">{item.fico}</u>
                        ) : item.customer.first_name !== "N/A" ? (
                          <Button
                            disableElevation
                            variant="outlined"
                            size="small"
                            className="runPrequalButton"
                            style={{
                              background: COLORS.secondary,
                              color: COLORS.white,
                              width: 120,
                              height: 26,
                            }}
                            onClick={() => {
                              if (
                                item.customer.first_name.length > 1 &&
                                item.customer.last_name.length > 1 &&
                                item.customer.street.length > 1 &&
                                item.customer.state.length > 1 &&
                                item.customer.city.length > 1 &&
                                item.customer.zipcode.length > 1
                              ) {
                                setPrequalAck(true);
                                setPrequalCustomer({
                                  ...prequalCustomer,
                                  _user_id: item.customer.user_id,
                                  _first_name: item.customer.first_name,
                                  _middle_name: item.customer.middle_name,
                                  _last_name: item.customer.last_name,
                                  _street: item.customer.street,
                                  _city: item.customer.city,
                                  _state: item.customer.state,
                                  _zipcode: item.customer.zipcode,
                                });
                              } else {
                                setPrequalInputError(true);
                              }
                            }}
                          >
                            Run Prequal
                          </Button>
                        ) : (
                          "N/A"
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          cursor: "default",
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        {item.compliance}
                      </TableCell>
                      <TableCell
                        style={{
                          cursor: "default",
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        {item.offerAfter}
                      </TableCell>
                      <TableCell
                        style={{
                          cursor: "default",
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        {item.soldAfter}
                      </TableCell>
                      <TableCell
                        style={{
                          cursor: "default",
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        {item.afterMarketGross !== "N/A"
                          ? " $ " +
                            parseInt(item.afterMarketGross, 10)
                              .toLocaleString("en-US")
                              .replace("-", "") +
                            " "
                          : "N/A"}
                      </TableCell>
                      <TableCell
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        {/* <FormControl>
                          <TextField
                            // size="small"
                            select
                            fullWidth={false}
                            style={{
                              height: 30,
                            }}
                            inputProps={{
                              style: {
                                padding: 2,
                                paddingLeft: 6,
                              },
                            }}
                            value={
                              item.dealStatus === 0 ? "" : item.vehicleSold
                            }
                            label={item.vehicleSold === 0 ? "select ---" : ""}
                            className={classes.textField}
                            InputLabelProps={{ shrink: false }}
                            // margin="none"
                            variant="outlined"
                            onChange={(event) => {
                              if (event.target.value !== 0) {
                                updateVehicleSold({
                                  variables: {
                                    vehicleSoldInput: {
                                      customerId: item.customer.customer_id,
                                      vehicleSoldStatus: event.target.value,
                                    },
                                  },
                                });
                              }
                            }}
                          >
                            <MenuItem value={3}>N/A</MenuItem>
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={2}>No</MenuItem>
                          </TextField>
                        </FormControl> */}
                      </TableCell>
                      <TableCell
                        style={{
                          maxWidth: 40,
                        }}
                      >
                        {
                          <FormControl>
                            <TextField
                              type="number"
                              fullWidth={false}
                              variant="outlined"
                              // size="small"
                              style={{
                                height: 6,
                              }}
                              inputProps={{
                                style: {
                                  padding: 2,
                                  paddingLeft: 6,
                                },
                              }}
                              value={
                                isFBGrossChanged
                                  ? item.customer_id === customerId
                                    ? fbGrossInputValue
                                    : item.fbGross === 0 ||
                                      item.vehicleSold === 2
                                    ? ""
                                    : item.fbGross
                                  : item.fbGross === 0 || item.vehicleSold === 2
                                  ? ""
                                  : item.fbGross
                              }
                              // margin="none"
                              onChange={(event) => {
                                if (
                                  item.vehicleSold === 1 ||
                                  item.vehicleSold === 3
                                ) {
                                  setIsFBGrossChanged(true);
                                  item.fbGross = setFbGrossInputValue(
                                    event.target.value
                                  );
                                  setCustomerId(item.customer.customer_id);
                                } else {
                                  setIsFBGrossChanged(false);
                                }
                                // console.log("fb", isFBGrossChanged);
                              }}
                              onBlur={(event) => {
                                event.target.value.length > 0 &&
                                (item.vehicleSold === 1 ||
                                  item.vehicleSold === 3)
                                  ? updateFBGross({
                                      variables: {
                                        fbGrossInput: {
                                          customerId: item.customer.customer_id,
                                          fbGrossValue: event.target.value,
                                        },
                                      },
                                    })
                                  : console.error("length 0");
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            {tableData && (
              <TablePagination
                rowsPerPageOptions={[10, 20]}
                component="div"
                count={totalRecordsCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </div>
        )}
      </Paper>
      <CustomerDialog
        onClose={() => {
          setCustomerDialogIsOpen(false);
          setCustomer(null);
        }}
        open={customerDialogIsOpen}
        customer={customer}
      />
      <VVDialog
        onClose={() => setVVDialogOpenIsOpen(false)}
        open={VVDialogOpenIsOpen}
        htmlReport={VVReport}
        // vehicleIds={vehicleIds ? VV : null}
        // zipcode={zipcode}
      />
      <YMMDialog
        onClose={() => {
          setYMMDialogOpenIsOpen(false);
          setYMMM(null);
        }}
        open={YMMDialogOpenIsOpen}
        ymmm={ymmm}
      />
      <FICODialog
        onClose={() => setFICODialogOpenIsOpen(false)}
        open={FICODialogOpenIsOpen}
        ficoHtml={ficoHtml}
      />
      <ConfirmationDialog
        open={showPrequalAck}
        title="Confirm"
        message="You understand by clicking the SUBMIT button, you are providing the company written instruction under the Fair Credit Reporting Act to obtain your personal credit information. You authorize this company to obtain such information solely to conduct a prequalification for credit. Submission will not affect your credit score"
        firstButtonLabel="Cancel"
        firstButtonFunction={() => {
          setPrequalAck(false);
        }}
        secondButtonLabel="Submit"
        secondButtonFunction={() => {
          callPrequal();
          setPrequalAck(false);
        }}
      />
      <InformationDialog
        open={showPrequalInputError}
        title="Info"
        message="Please update mandatory details of customer to run prequal"
        firstButtonLabel="OK"
        firstButtonFunction={() => {
          setPrequalInputError(false);
        }}
      />
    </div>
  );
};

// export default SLGrid;
export default isLoadingHOC(SLGrid);
