import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import { useReactToPrint } from "react-to-print";
import { Card, DialogTitle, Button } from "@material-ui/core";
import { COLORS } from "../../colors";

import "./InformationDialog.styles.css";

const InformationDialog = (props) => {
  const history = useHistory();
  const okayHandler = () => history.push("/");
  const { open, title, message, firstButtonLabel, firstButtonFunction } = props;
  const Icon = props.icon;
  return (
    <div>
      <Dialog
        onClose={firstButtonFunction}
        open={open}
        fullWidth
        maxWidth={"sm"}
        scroll={"paper"}
      >
        <DialogTitle
          style={{
            background: "#283b54",
            color: COLORS.white,
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent className="DialogContent">
          <div>
            <Typography>{message}</Typography>
          </div>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#f2f2f2" }}>
          <div className="HBottomButton">
            <Button
              className="HDialogButton"
              style={{ color: COLORS.primary, marginRight: 4 }}
              onClick={firstButtonFunction}
            >
              {firstButtonLabel}
            </Button>
            {/* <Button
              className="HDialogButton"
              style={{ backgroundColor: COLORS.primary, color: COLORS.white }}
              onClick={secondButtonFunction}
            >
              {secondButtonLabel}
            </Button> */}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InformationDialog;
